import { Component, OnInit } from '@angular/core';


import {FormGroup} from '@angular/forms'
import { NgForm } from '@angular/forms';
//import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { Http, Response } from '@angular/http';
//import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ServiceService } from '../service/service.service';
declare var $: any;
@Component({
  selector: 'app-apropo',
  templateUrl: './apropo.component.html',
  styleUrls: ['./apropo.component.scss']
})
export class ApropoComponent implements OnInit {

  tableau= [];
  constructor(private http: Http,public param: ServiceService, private router: Router) {

   }

  ngOnInit() {

 
  }

}
