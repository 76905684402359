import { Component, OnInit } from '@angular/core';

import {FormGroup} from '@angular/forms'
import { NgForm } from '@angular/forms';
//import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ServiceService } from '../service/service.service';
declare var $: any;
@Component({
  selector: 'app-categorie',
  templateUrl: './categorie.component.html',
  styleUrls: ['./categorie.component.scss']
})
export class CategorieComponent implements OnInit {



  
      public erreur =false;
      public message='';
       settings= {
        
    
       noDataMessage: "Aucun section n'est enrégistré pour le moment!",
       add: {
        addButtonContent: `<img src="../assets/img/plus.png">`,
        createButtonContent: `<img src="../assets/img/checkmark.png">`,
        cancelButtonContent: `<img src="../assets/img/close.png">`,
        confirmCreate: true,
      },
      edit: {
        editButtonContent: `<img src="../assets/img/pencil.png">`,
        saveButtonContent: `<img src="../assets/img/checkmark.png">`,
        cancelButtonContent: `<img src="../assets/img/close.png">`,
        confirmSave: true,
      },
      delete: {
        deleteButtonContent: `<img src="../assets/img/trash.png">`,
        confirmDelete: true,
      },
       columns: {
        code: {
           title: 'Code',
           type: 'string',
           width:'17%',
           editable: false,
         },
         nom_categorie: {
           title: 'Nom de la catégorie',
           type: 'string',
      
         },
     
       
       
       },
       pager: {
         display: true,
         perPage: 7,
       },
       actions: {
        add: true,
         position: 'left',
       },
       attr: {
         class: 'table table-hover'
       }
     };
    
     nbusers = 0;
     error:string;
    
     public items1: any;
     public url1:any;
     messageF='';
     data3: any;
     tableau= [];
    profilePicture: string = null;
       constructor(private http: Http, private router: Router,public param: ServiceService,private http1: HttpClient) { 
    
    
      
    
    
        this.recupSECTION().subscribe(
          data => {
            this.items1 = JSON.parse(JSON.stringify(data)); 
            this.nbusers = this.items1.length;
          },
        error => this.error = error.message
            );
    
          
       }
       
       ngOnInit() {

        this.tableau=localStorage.getItem('user').split(";");
        if (!this.tableau.includes('Créer une catégorie')) {
          this.router.navigate(['Acceil']);
          }

    
           }
           recupSECTION() {
            this.url1=this.param.lien+'recup_categorie.php'
            return this.http
            .get(this.url1)
            .map((data: Response) => data.json())
          }
    
    
    
    
          
          onDeleteConfirm(event) {
      
    
            if (window.confirm('Êtes-vous sûr de vouloir supprimer cette catégorie?')) {
             
    
              this.http.get(this.param.lien+'delete_categorie.php?code='+event.data.code+'&login='+ localStorage.getItem('login')).subscribe(data => {
                this.showNotification('top','center','Suppression effectué',1);
                   }); 
                   event.confirm.resolve();
                  } else {
              event.confirm.reject();
            }
          }
    
          onSaveConfirm(event) {
            this.http.get(this.param.lien+'Modif_categorie.php?code='+event.newData.code
            +'&nom_categorie='+event.newData.nom_categorie.toUpperCase()+'&login='+ localStorage.getItem('login')).subscribe(data => {
    
              this.showNotification('top','center','Modification effectué',1);
      
                 }); 
                 event.confirm.resolve();
    
          }
          onCreateConfirm(event) {
            this.http.get(this.param.lien+'add_categorie.php?code='+event.newData.code.toUpperCase()
            +'&nom_categorie='+event.newData.nom_categorie.toUpperCase()+'&login='+ localStorage.getItem('login')).subscribe(data => {
              this.showNotification('top','center','Enregistrement effectué',1);
           
            
                 }); 
               
                 event.confirm.resolve();
                 //window.location.reload();
          }
    
    
    
    
       showNotification(from, align, messagej,t){
        const type = ['','info','success','warning','danger'];
    
        const color = Math.floor((Math.random() * 4) + 1);
    
        $.notify({
            icon: "notifications",
            message: messagej
    
        },{
            type: type[t],
            timer: 4000,
            placement: {
                from: from,
                align: align
            },
            template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
              '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
              '<i class="material-icons" data-notify="icon">notifications</i> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
                '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              '</div>' +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
            '</div>'
        });
    }
    
    
    
    
     }
     