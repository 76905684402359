import { Injectable } from '@angular/core';
import { Http, ResponseContentType } from '@angular/http';
import * as html2canvas from 'html2canvas';
import 'jspdf';
declare let jsPDF;


@Injectable()
export class AppGlobals {
    readonly baseAPIUrl: string = 'http://localhost/MyCO-webservice/web/app_dev.php/';
    readonly baseAppUrl: string = 'https://api.destiny-innovation.com/v2/web/app_dev.php/';
    readonly ressources: string = 'http://docs.destiny-innovation.com/';
    //public now = new Date();
    constructor(private http: Http){}

public arraytostring(items){
  let chaine:string='';
  let cpt=0;
  for(let item in items){
      chaine= chaine+items[cpt]+'; ';
      if(cpt<items.lenght){
        chaine = chaine+";";
      }
      cpt++;
  }
  return chaine;
}

public genererPDF(nom, colonnes, rows: any[]) { 
    let nows = new Date();
    // Only pt supported (not mm or in)
    let doc = new jsPDF('l', 'pt', 'a4');
    var img = new Image();
    let now = +nows.getHours()+'h'+nows.getMinutes()+'min'+nows.getSeconds();
    var totalPagesExp = doc.pageCount;
    let pageNumber = doc.internal.getNumberOfPages();

 if(rows.length!==0){
    doc.autoTable(colonnes, rows, {
        theme: 'striped',
          styles: {
            fillColor: [1, 1, 20],
            overflow: 'linebreak',
            fontSize: 15,
            columnWidth: 'wrap',
            theme: 'striped',// 'striped', 'grid' or 'plain'
            startY: false, // false (indicates margin top value) or a number
            pageBreak: 'auto', // 'auto', 'avoid' or 'always'
            tableWidth: 'wrap', // 'auto', 'wrap' or a number, 
            showHeader: 'firstPage', // 'everyPage', 'firstPage', 'never',
            tableLineColor: 100, // number, array (see color section below)
            tableLineWidth: 2, rowPageBreak: 'auto', halign: 'justify'

          },
         columnStyles: {
            id: {fillColor: [15, 15,25]},
            1: {cellWidth: 'auto'},
            2: {cellWidth: 'auto'},
            3: {cellWidth: 'auto'},
            4: {cellWidth: 'auto'},
           //columnWidth: 'wrap',
          },
          bodyStyles: {valign: 'top'},
          headerStyles: {theme: 'striped'},
          margin: {top: 60},
          addPageContent: function(data) {
            doc.text(nom + '    Nombre Total : ' + rows.length, 150, 40);
            //fontSize: 25;
           /* var str = "Page " + data.pageCount;
            // Total page number plugin only available in jspdf v1.0+
            if (typeof doc.putTotalPages === 'function') {
                str = str;
            }
            str = str + ". Generated on " + nows;
           // doc.text(str, 0, doc.internal.pageSize.height - 5);*/
          
          }
      });
      img.onload = function() {
        img.src = 'assets/img/head.png';
        doc.addImage(img, 'png', 10, 10, 190, 30);  
       };
        img.crossOrigin = "";  
        img.src = 'assets/img/head.png';
    doc.pageNumber;
    doc.save(nom+' '+nows.toDateString()+' - '+now+'.pdf');
 }else{
     window.alert('Impossible de generer le document. Aucune donnée trouvée')
 }

}
public downloadFile(url) {
  return this.http
    .get(url, {responseType: ResponseContentType.Blob,search:URL} // query string if have
    )
    .map(res => {
      return {
        filename: 'filename.pdf',
        data: res.blob()
      };
    })
    .subscribe(res => {
        console.log('start download:',res);
        var url = window.URL.createObjectURL(res.data);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }, error => {
        console.log('download error:', JSON.stringify(error))
      }, () => {
        console.log('Completed file download.')
      });
    }

}