import { Component, OnInit } from '@angular/core';

import {FormGroup} from '@angular/forms'
import { NgForm } from '@angular/forms';
//import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ServiceService } from '../../service/service.service';
import { HttpClient } from '@angular/common/http';
declare var $: any;
@Component({
  selector: 'app-modifier-produit',
  templateUrl: './modifier-produit.component.html',
  styleUrls: ['./modifier-produit.component.scss']
})
export class ModifierProduitComponent implements OnInit {



  
  
  
      
    
            public message='';
             settings= {
              
          
             noDataMessage: "Aucun produit n'est enrégistré pour le moment!",
             add: {
              addButtonContent: `<img src="../assets/img/plus.png">`,
              createButtonContent: `<img src="../assets/img/checkmark.png">`,
              cancelButtonContent: `<img src="../assets/img/close.png">`,
              confirmCreate: false,
            },
            edit: {
              editButtonContent: `<img src="../assets/img/pencil.png">`,
              saveButtonContent: `<img src="../assets/img/checkmark.png">`,
              cancelButtonContent: `<img src="../assets/img/close.png">`,
              confirmSave: true,
            },
            delete: {
              deleteButtonContent: `<img src="../assets/img/trash.png">`,
              confirmDelete: false,
            },
            
             columns: {

              id: {
                title: 'ID',
                type: 'string',
                editable: false,
              },
              Nom_commercial: {
                 title: 'Désignation/DCI',
                 type: 'string',
                 width:'20%',
               },
               Dosage: {
                title: 'Dosage',
                type: 'string',
             
              },
               forme: {
                title: 'Forme',
                type: 'string',
                width:'17%',
              },
             
              Conditionnement: {
                title: 'Conditionnement',
                type: 'string',
             
              },
               Date_enregistrement: {
                title: 'Date approvisionnement',
                type: 'string'
              },
           
              
              prix_achat: {
                title: 'Prix d\'achat',
                type: 'string',
             
              },
             
              id_categorie: {
                title: 'catégorie',
                editor: {
                  type: 'list',
                  config: {
                    selectText: 'Select',
                    list:JSON.parse(localStorage.getItem('categorie')),
                  },
                },
                type: 'string',
                width:'20%',
              }
             },
    
    
             pager: {
               display: true,
               perPage: 7,
             },
             actions: {
              delete: false,
              add: false,
              edit: true,
              select: false,

           
               position: 'left',
             },
             attr: {
               class: 'table table-hover'
             }
           };
          















          nbusers1 = 0;
           nbusers = 0;
           error:string;
          
           public items1: any;
           public items12: any;
           public url1:any;
           messageF='';
           tableau= [];
    
     
    
    
          profilePicture: string = null;
             constructor(private http: Http, private router: Router,public param: ServiceService,private http1: HttpClient) { 
    
             
          
              this.recupproduit().subscribe(
                data => {
                  this.items1 = JSON.parse(JSON.stringify(data)); 
                  this.nbusers = this.items1.length;
                },
              error => this.error = error.message
                  );
          
                

             }
             
    
    
    
             ngOnInit() {
    
                 
    
              this.tableau=localStorage.getItem('user').split(";");
              if (!this.tableau.includes('Modifier un produit')) {
                this.router.navigate(['Acceil']);
                }
    
    
                 }
                 recupproduit() {
                  this.url1=this.param.lien+'recup_produitCONSULT.php'
                  return this.http
                  .get(this.url1)
                  .map((data: Response) => data.json())
                }
            
               
          
          
                
             
          
                onSaveConfirm(event) {
  
  
                  this.http.get(this.param.lien+'Modif_produit.php?Nom_commercial='
                  +event.newData.Nom_commercial
                 +'&Dosage='+event.newData.Dosage+'&prix_achat='+event.newData.prix_achat
                  +'&id_categorie='+event.newData.id_categorie+'&Forme='+event.newData.forme+'&id='+event.data.id
                  +'&login='+ localStorage.getItem('login')+'&Conditionnement='+event.newData.Conditionnement).subscribe(data => {
                    
                    this.showNotification('top','center','Modification effectué',1);
                 
                       }); 
                       event.confirm.resolve();
          
                }
             
          
       
          
          
             showNotification(from, align, messagej,t){
              const type = ['','info','success','warning','danger'];
          
              const color = Math.floor((Math.random() * 4) + 1);
          
              $.notify({
                  icon: "notifications",
                  message: messagej
          
              },{
                  type: type[t],
                  timer: 4000,
                  placement: {
                      from: from,
                      align: align
                  },
                  template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
                    '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
                    '<i class="material-icons" data-notify="icon">notifications</i> ' +
                    '<span data-notify="title">{1}</span> ' +
                    '<span data-notify="message">{2}</span>' +
                    '<div class="progress" data-notify="progressbar">' +
                      '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                    '</div>' +
                    '<a href="{3}" target="{4}" data-notify="url"></a>' +
                  '</div>'
              });
          }
          
          
          
          
           }
           