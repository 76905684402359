import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
//import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { ServiceService } from '../service/service.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
declare var $: any;
@Component({
  selector: 'app-acceil',
  templateUrl: './acceil.component.html',
  styleUrls: ['./acceil.component.scss']
})
export class AcceilComponent implements OnInit {
  profilpicture='../assets/img/Doctor_Male_icon-icons.com_75051.png';
  profilpicture1='../assets/img/kendal-L4iKccAChOc-unsplash.jpg';
public nompharma: string;
 nomuser=localStorage.getItem('nom');
 pnomuser=localStorage.getItem('prenomUser');

 profilpicture4='../assets/img/re1.jpg';
 profilpicture5='../assets/img/Doctor_Male_icon-icons.com_75051.png';
 profilpicture6='../assets/img/Doctor_Male_icon-icons.com_75051.png';
 public url1:any;

  constructor(private http: Http, private router: Router,public param: ServiceService,private http1: HttpClient) { 

    this.nompharma=localStorage.getItem('nomPharma');
  }

  ngOnInit() {

    if (localStorage.getItem('verif')==='true') {
      window.location.reload();
      localStorage.removeItem('verif');
    }
    this. recupinfopharma().subscribe(
      data => {
        data.forEach(obj => {

          localStorage.setItem('nomPharma', obj.nom);
    
        });
      },
          );
    this.nompharma=localStorage.getItem('nomPharma');




  }
  recupinfopharma() {
    this.url1=this.param.lien+'recup_infoPharma.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
}
