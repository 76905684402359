
import { Component, OnInit } from '@angular/core';
import * as Chartist from 'chartist';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';


import { DatePipe } from '@angular/common';
import {FormGroup} from '@angular/forms'
import { NgForm } from '@angular/forms';
//import {  FormGroup, FormControl, Validators } from '@angular/forms';

import { Http, Response } from '@angular/http';
//import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ServiceService } from '../service/service.service';
declare var $: any;
@Component({
  selector: 'app-detail-produit',
  templateUrl: './detail-produit.component.html',
  styleUrls: ['./detail-produit.component.scss'],
  providers: [DatePipe]
})
export class DetailProduitComponent implements OnInit {
  /*public barChartOptions:any = {
    scaleShowVerticalLines: true,
    responsive: true,
    scales: { xAxes: ['ffff'], yAxes: ['ttttt'] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
  }
}
public barChartType:string = 'bar';
public barChartLegend:boolean = true;
public barChartData:any[];
public barChartLabels:string[] ;*/
//public barChartLabels: any[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];


public barChartOptions: ChartOptions = {
  responsive: true,
  // We use these empty structures as placeholders for dynamic theming.
  scales: { xAxes: [{}], yAxes: [{}] },
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'end',
    }
  }
};
public barChartLabels: Label[] ;
public barChartType: ChartType = 'line';
public barChartLegend = true;
public barChartPlugins = [pluginDataLabels];

public barChartData: ChartDataSets[] = [
  { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
];



public url1:any;
public categorie= [];
public categorieC:any;
tab_catego= [];
tab_qt:number[];
public items1: any[];
public items11: any[];
public items22: any[];
messageV='LISTE DES VENTES DU JOUR';
messageF='';
nbusers = 0;
venteTotal = 0;
venteTotal2 = 0;
tableau= [];
NUMO='';
idpro='';
NOM='';

  constructor(private http: Http,public param: ServiceService,private datePipe: DatePipe, private router: Router) { 



  }

  ngOnInit() {


    

    this.NUMO=localStorage.getItem('N_lot_detail');
    this.NOM=localStorage.getItem('nom_produit');
    this.idpro=localStorage.getItem('idpro');

    this.tab_catego= [];
    this.tab_qt = [];
    this.items22= [];
    this.recup_vente_produit().subscribe(
      data => {
        this.items22 = JSON.parse(JSON.stringify(data)); 
        console.log(this.items22);
        for (var j=0;j<this.items22.length;j++){ 
          this.tab_catego.push(''+this.items22[j].datefacruration+'');
          let qtv1=this.items22[j].Quantite;
          let f=+qtv1;

          this.tab_qt.push(f);
        }
        console.log("NP1    "+this.tab_catego);
        console.log("NP2    "+ this.tab_qt);
        this.barChartLabels = this.tab_catego;
        this.barChartData=[
          { data:  this.tab_qt, label: 'Vente du produit par date' }
        ];
        /*this.barChartLabels = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
        this.barChartData= [
          {data: [65, 59, 80, 81, 56, 55, 40], label: 'Vente du produit par date',
          backgroundColor: '#e4e4e4f3',
          textColor: '#e4e4e4f3',
          borderColor: 'purple',
          hoverBackgroundColor: 'purple',
          hoverBorderColor: 'red',
          }
        ];*/
      },
        );
  }

  onChange2(deviceValue2) {
    this.barChartType=deviceValue2;
   }
   recupvente2date(DD:string,DF:string) {
    this.url1=this.param.lien+'recup_vente_produi_date.php?dateD='+DD+'&dateF='+DF+'&N_lot_detail='+
    localStorage.getItem('N_lot_detail')+'&idpro='+ localStorage.getItem('idpro')
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
   onFormSubmit3(userForm: NgForm) {
    this.tab_catego= [];
    this.tab_qt = [];
    this.items22= [];


    this.recupvente2date(this.datePipe.transform(userForm.value.dated, 'yyyy-MM-dd'),this.datePipe.transform(userForm.value.datef, 'yyyy-MM-dd')).subscribe(
      data => {
               this.items22 = JSON.parse(JSON.stringify(data)); 
        console.log(this.items22);
        for (var j=0;j<this.items22.length;j++){ 
          this.tab_catego.push(''+this.items22[j].datefacruration+'');
          let qtv1=this.items22[j].Quantite;
          let f=+qtv1;

          this.tab_qt.push(f);
        }
        console.log("NP1    "+this.tab_catego);
        console.log("NP2    "+ this.tab_qt);
        this.barChartLabels = this.tab_catego;
        this.barChartData=[
          { data:  this.tab_qt, label: 'Vente du produit par date' }
        ];


      },
        );


  }


   
  recup_vente_produit() {

    console.log("NP    "+localStorage.getItem('N_lot_detail'));

    this.url1=this.param.lien+'recup_vente_produi_date.php?dateD='+localStorage.getItem('dateD')+'&dateF='+localStorage.getItem('dateF')
    +'&N_lot_detail='+localStorage.getItem('N_lot_detail')+'&login='+ localStorage.getItem('login')
    +'&idpro='+ localStorage.getItem('idpro')
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())

  }
}
