import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

//import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ModifPassComponent } from './modif-pass/modif-pass.component';
import { LoginComponent } from './login/login.component';
import { CategorieComponent } from './categorie/categorie.component';
import { CreerUserComponent } from './creer-user/creer-user.component';
import { EnregProduitComponent } from './produit/enreg-produit/enreg-produit.component';
import { ConsulterProduitComponent } from './produit/consulter-produit/consulter-produit.component';
import { ModifierProduitComponent } from './produit/modifier-produit/modifier-produit.component';
import { SupprimerProduitComponent } from './produit/supprimer-produit/supprimer-produit.component';
import { EffectuerVenteComponent } from './vente/effectuer-vente/effectuer-vente.component';
import { ViewPdfComponent } from './vente/view-pdf/view-pdf.component';
import { ParametreComponent } from './parametre/parametre.component';
import { GestionstockComponent } from './gestionstock/gestionstock.component';
import { DetailProduitComponent } from './detail-produit/detail-produit.component';
import { AcceilComponent } from './acceil/acceil.component';
import { JournalComponent } from './journal/journal.component';
import { ImprimerComponent } from './imprimer/imprimer.component';
import { GetionInventaireComponent } from './getion-inventaire/getion-inventaire.component';
import { AnnullerVenteComponent } from './annuller-vente/annuller-vente.component';
import { ImporterProduitComponent } from './importer-produit/importer-produit.component';
import { ProfileComponent } from './profile/profile.component';

import { ApropoComponent } from './apropo/apropo.component';



const routes: Routes =[
 


  { path: 'dashboard',      component: DashboardComponent },
  { path: 'user-profile',   component: UserProfileComponent },
  { path: 'login',      component: LoginComponent },
  { path: 'Categorie',      component: CategorieComponent },
  { path: 'CreerUser',      component: CreerUserComponent },
  { path: 'ModifPass',      component: ModifPassComponent},
  { path: 'EnregProduit',      component: EnregProduitComponent},
  { path: 'ConsulterProduit',      component: ConsulterProduitComponent},
  { path: 'ModifierProduit',      component: ModifierProduitComponent},
  { path: 'SupprimerProduit',      component: SupprimerProduitComponent},
  { path: 'EffectuerVente',      component: EffectuerVenteComponent},
  { path: 'ViewPdf',      component: ViewPdfComponent},
  { path: 'Parametre',      component: ParametreComponent},
  { path: 'Gestionstock',      component: GestionstockComponent},
  { path: 'DetailProduit',      component: DetailProduitComponent},
  { path: 'Acceil',      component: AcceilComponent},
  { path: 'Journal',      component: JournalComponent},
  { path: 'Imprimer',      component: ImprimerComponent},
  { path: 'AnnullerVente',      component: AnnullerVenteComponent},
  { path: 'ImporterProduit',      component: ImporterProduitComponent},
  { path: 'Profile',      component: ProfileComponent},

  { path: 'Apropo',      component: ApropoComponent},
  { path: '',               redirectTo: 'Acceil', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
       useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
