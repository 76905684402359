import { Component, OnInit } from '@angular/core';

import { DatePipe } from '@angular/common';
import {FormGroup} from '@angular/forms'
import { NgForm } from '@angular/forms';
//import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ServiceService } from '../service/service.service';
import { HttpClient } from '@angular/common/http';
declare var $: any;
@Component({
  selector: 'app-gestionstock',
  templateUrl: './gestionstock.component.html',
  styleUrls: ['./gestionstock.component.scss'],
  providers: [DatePipe],
})
export class GestionstockComponent implements OnInit {

    
  public message='';
  settings= {
   

    noDataMessage: "Aucun produit n'est enrégistré pour le moment!",
    add: {
      addButtonContent: `<img src="../assets/img/plus.png">`,
      createButtonContent: `<img src="../assets/img/checkmark.png">`,
      cancelButtonContent: `<img src="../assets/img/close.png">`,
      confirmCreate: true,
    },
    edit: {
      editButtonContent: `<img src="../assets/img/pencil.png">`,
      saveButtonContent: `<img src="../assets/img/checkmark.png">`,
      cancelButtonContent: `<img src="../assets/img/close.png">`,
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: `<img src="../assets/img/trash.png">`,
      confirmDelete: true,
    },
   
    columns: {

  
     Nom_commercial: {
        title: 'Désignation/DCI',
        type: 'string',
        editable: false,
      }, 
      Dosage: {
        title: 'Dosage',
        type: 'string',
        editable: false,
        width:'10%',
      },
      forme: {
        title: 'Forme',
        type: 'string',
        editable: false,
      },
      n_lot: {
        title: 'Numéro de lot *',
        type: 'string',
      },
      Date_expiration: {
        title: 'date expiration *',
        type: 'string',
      },
      Prix_populaire: {
        title: 'prix de vente *',
        type: 'string',
        width:'10%',
      }
    },
  
  
    pager: {
      display: true,
      perPage: 7,
    },
    actions: {
      delete: false,
      add: false,
      edit: true,
      select: false
    },
    attr: {
      class: 'table table-hover'
    }
  };





  settings1= {
   

    noDataMessage: "Aucun produit n'est enrégistré pour le moment!",
    add: {
      addButtonContent: `<img src="../assets/img/plus.png">`,
      createButtonContent: `<img src="../assets/img/checkmark.png">`,
      cancelButtonContent: `<img src="../assets/img/close.png">`,
      confirmCreate: true,
    },
    edit: {
      editButtonContent: `<img src="../assets/img/pencil.png">`,
      saveButtonContent: `<img src="../assets/img/checkmark.png">`,
      cancelButtonContent: `<img src="../assets/img/close.png">`,
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: `<img src="../assets/img/trash.png">`,
      confirmDelete: true,
    },
   
    columns: {

  
     Nom_commercial: {
        title: 'Désignation/DCI',
        type: 'string',
        editable: false,
        width:'18%',
      },    
      Dosage: {
        title: 'Dosage',
        type: 'string',
        editable: false,
        width:'10%',
      },
      forme: {
        title: 'Forme',
        type: 'string',
        editable: false,
      },  
       n_lot: {
        title: 'Numéro de lot',
        type: 'string',
      },
      Date_expiration: {
        title: 'date expiration',
        type: 'string',
      },
     
      Quantite: {
        title: 'Stock Disponible',
        type: 'string',
        editable: false,
        width:'10%',
      },
      dateappro: {
        title: 'date de stockage',
        type: 'string',
        editable: false,
      },

      Prix_populaire: {
        title: 'prix de vente',
        type: 'string',
        width:'10%',
      }
    },
  
  
    pager: {
      display: true,
      perPage: 7,
    },
    actions: {
      delete: true,
      add: false,
      edit: true,
      select: false,
   
   
       position: 'left',
     },
    attr: {
      class: 'table table-hover'
    }
  };









  settings2= {
   

  noDataMessage: "Aucun produit n'est enrégistré pour le moment!",
  add: {
    addButtonContent: `<img src="../assets/img/plus.png">`,
    createButtonContent: `<img src="../assets/img/checkmark.png">`,
    cancelButtonContent: `<img src="../assets/img/close.png">`,
    confirmCreate: true,
  },
  edit: {
    editButtonContent: `<img src="../assets/img/pencil.png">`,
    saveButtonContent: `<img src="../assets/img/checkmark.png">`,
    cancelButtonContent: `<img src="../assets/img/close.png">`,
    confirmSave: true,
  },
  delete: {
    deleteButtonContent: `<img src="../assets/img/trash.png">`,
    confirmDelete: true,
  },
 
  columns: {


   Nom_commercial: {
      title: 'Désignation/DCI',
      type: 'string',
      editable: false,
      width:'18%',
    }, 
    Dosage: {
      title: 'Dosage',
      type: 'string',
      editable: false,
      width:'10%',
    },
    forme: {
      title: 'Forme',
      type: 'string',
      editable: false,
    },
    n_lot: {
      title: 'Numéro de lot',
      type: 'string',
      editable: false,
    },
    Date_expiration: {
      title: 'date expiration',
      type: 'string',
      editable: false,
    },
        
    Quantite: {
      title: 'Stock disponible',
      type: 'string',
      editable: false,
      width:'10%',
    },
    dateappro: {
      title: 'date de stockage',
      type: 'string',
      editable: false,
    },
   

    Prix_populaire: {
      title: 'prix de vente',
      type: 'string',
      editable: false,
      width:'10%',
    },
    QuantiteN: {
      title: 'Aprovisionnement',
      type: 'string',
      width:'10%',
    }
  },


  pager: {
    display: true,
    perPage: 7,
  },
  actions: {
   delete: false,
   add: false,
   edit: true,
   select: false,


    position: 'left',
  },
  attr: {
    class: 'table table-hover'
  }
};

nbusers = 0;
nbusers1 = 0;
error:string;

public items1: any;
public items2: any;
public url1:any;
messageF='';
tableau= [];

ancien_num_lot='';


profilePicture: string = null;
  constructor(private http: Http, private router: Router,public param: ServiceService,private http1: HttpClient,private datePipe: DatePipe) { 

  

   this.recupproduitstock().subscribe(
     data => {
       this.items1 = JSON.parse(JSON.stringify(data)); 
       this.nbusers = this.items1.length;
     },
   error => this.error = error.message
       );


       this.recupproduitstock1().subscribe(
        data => {
          this.items2 = JSON.parse(JSON.stringify(data)); 
          this.nbusers1 = this.items2.length;
        },
      error => this.error = error.message
          );
     
  }
  



  ngOnInit() {

    this.tableau=localStorage.getItem('user').split(";");
    if (!this.tableau.includes('Gestion du stock')) {
      this.router.navigate(['Acceil']);
      }



      }
      recupproduitstock() {
        this.url1=this.param.lien+'recup_produitstock.php'
        return this.http
        .get(this.url1)
        .map((data: Response) => data.json())
      }

      recupproduitstock1() {
        this.url1=this.param.lien+'recup_produitstockCONSULT.php'
        return this.http
        .get(this.url1)
        .map((data: Response) => data.json())
      }


      recupproduit() {
       this.url1=this.param.lien+'recup_produit_aprovi.php'
       return this.http
       .get(this.url1)
       .map((data: Response) => data.json())
     }
    


     
     onrowSelect(event){
        
      this.ancien_num_lot=event.data.n_lot;

            }
            onrowSelect2(event){
        
        
        
                    }
            onDeleteConfirm2(event) {
              
            
              if (window.confirm('Êtes-vous sûr de vouloir supprimer ce produit du stock?')) {
               
      
                this.http.get(this.param.lien+'delete_produitstock.php?id='+event.data.id+'&n_lot='+ event.data.n_lot
                +'&login='+ localStorage.getItem('login')).subscribe(data => {
                  this.showNotification('top','center','Suppression effectué',1);
                     }); 
                     event.confirm.resolve();
                    } else {
                event.confirm.reject();
              }
            }
            onSaveConfirm2(event) {

            
              this.http.get(this.param.lien+'Modif_info_stock.php?id='+event.data.id
              +'&login='+ localStorage.getItem('login')+'&nouveau_lot='
              +event.newData.n_lot+'&ancien_lot='+ event.data.n_lot
              +'&Prix_populaire='+ event.newData.Prix_populaire
              +'&Date_expiration='+ event.newData.Date_expiration).subscribe(data => {

                this.recupproduitstock1().subscribe(
                  data => {
                    this.items2 = JSON.parse(JSON.stringify(data)); 
                    this.nbusers1 = this.items2.length;
                  }, 
                error => this.error = error.message
                    );
                this.showNotification('top','center','Modification effectué',1);
             
                   }); 

                   event.confirm.resolve();


                        }
                      


            onSaveConfirm1(event) {

             
              if(event.newData.n_lot.length<=0||event.newData.Prix_populaire.length<=0||event.newData.Date_expiration.length<=0){ 

                this.showNotification('top','center','Les champs obligatoires ne sont pas renseigné',4);
              }else{ 
              this.http.get(this.param.lien+'Modif_stock_add.php?id='+event.data.id
              +'&login='+ localStorage.getItem('login')+'&nouveau_lot='
              +event.newData.n_lot+'&Prix_populaire='+ event.newData.Prix_populaire
              +'&Date_expiration='+event.newData.Date_expiration).subscribe(data => {

                this.recupproduitstock1().subscribe(
                  data => {
                    this.items2 = JSON.parse(JSON.stringify(data)); 
                    this.nbusers1 = this.items2.length;
                  }, 
                error => this.error = error.message
                    );
                this.showNotification('top','center','Produit ajouté au stock',1);
             
                   }); 

                   event.confirm.resolve();


                        }
                      }












     onSaveConfirm(event) {


      var Eqt=event.data.Quantite
      var Nqt=event.newData.QuantiteN
    var t=+Eqt;
    var j=+Nqt;
    var qt=t+j;

       this.http.get(this.param.lien+'Modif_stock.php?id='+event.data.id+'&Quantite='+qt
       +'&Nqt='+Nqt+'&login='+ localStorage.getItem('login')+'&ancien_lot='+ event.data.n_lot
       +'&Date_expiration='+ event.data.Date_expiration+'& Prix_populaire='+ event.data.Prix_populaire).subscribe(data => {
                this.recupproduitstock1().subscribe(
                  data => {
                    this.items2 = JSON.parse(JSON.stringify(data)); 
                    this.nbusers1 = this.items2.length;
                  },
                error => this.error = error.message
                    );
         this.showNotification('top','center','Aprovisionnement effectué',1);
      
            }); 
            event.confirm.resolve();
     }
  




  showNotification(from, align, messagej,t){
   const type = ['','info','success','warning','danger'];

   const color = Math.floor((Math.random() * 4) + 1);

   $.notify({
       icon: "notifications",
       message: messagej

   },{
       type: type[t],
       timer: 4000,
       placement: {
           from: from,
           align: align
       },
       template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
         '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
         '<i class="material-icons" data-notify="icon">notifications</i> ' +
         '<span data-notify="title">{1}</span> ' +
         '<span data-notify="message">{2}</span>' +
         '<div class="progress" data-notify="progressbar">' +
           '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
         '</div>' +
         '<a href="{3}" target="{4}" data-notify="url"></a>' +
       '</div>'
   });
}




}
