import { Component, OnInit } from '@angular/core';


import {FormGroup} from '@angular/forms'
import { NgForm } from '@angular/forms';
//import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ServiceService } from '../service/service.service';
import { HttpClient } from '@angular/common/http';
declare var $: any;
@Component({
  selector: 'app-getion-inventaire',
  templateUrl: './getion-inventaire.component.html',
  styleUrls: ['./getion-inventaire.component.scss']
})
export class GetionInventaireComponent implements OnInit {

    
  public message='';
  settings= {
   

  noDataMessage: "Aucun produit n'est enrégistré pour le moment!",
  add: {
    addButtonContent: `<img src="../assets/img/plus.png">`,
    createButtonContent: `<img src="../assets/img/checkmark.png">`,
    cancelButtonContent: `<img src="../assets/img/close.png">`,
    confirmCreate:false,
  },
  edit: {
    editButtonContent: `<img src="../assets/img/pencil.png">`,
    saveButtonContent: `<img src="../assets/img/checkmark.png">`,
    cancelButtonContent: `<img src="../assets/img/close.png">`,
    confirmSave: true,
  },
  delete: {
    deleteButtonContent: `<img src="../assets/img/trash.png">`,
    confirmDelete: false,
  },
 
  columns: {
    id: {
      title: 'ID',
      type: 'string',
      editable: false,
    },
   N_lot: {
     title: 'Numéro de lot',
     type: 'string',
     editable: false,
   },
   Nom_commercial: {
      title: 'Nom commercial',
      type: 'string',
      editable: false,
    },
    Quantite: {
      title: 'Stock',
      type: 'string',
      editable: false,
    },
    Quantit: {
      title: 'Qt Non Utilisable',
      type: 'string',
   
    },
  },


  pager: {
    display: true,
    perPage: 7,
  },
  actions: {
   delete: false,
   add: false,
   edit: true,
   select: false,


    position: 'left',
  },
  attr: {
    class: 'table table-hover'
  }
};

nbusers = 0;
error:string;

public items1: any;
public url1:any;
messageF='';
tableau= [];




profilePicture: string = null;
  constructor(private http: Http, private router: Router,public param: ServiceService,private http1: HttpClient) { 

  

   this.recupproduit().subscribe(
     data => {
       this.items1 = JSON.parse(JSON.stringify(data)); 
       this.nbusers = this.items1.length;
     },
   error => this.error = error.message
       );

     
  }
  



  ngOnInit() {




      }
      recupproduit() {
       this.url1=this.param.lien+'recup_produit.php'
       return this.http
       .get(this.url1)
       .map((data: Response) => data.json())
     }
    


     


     onSaveConfirm(event) {




      var Eqt=event.data.Quantite
      var Nqt=event.newData.Quantit
    var t=+Eqt;
    var j=+Nqt;
    var qt=t+j;

       this.http.get(this.param.lien+'add_inventaire.php?id='+event.data.id+'&Quantite='+event.newData.Quantit
       +'&login='+ localStorage.getItem('login')).subscribe(data => {

         this.showNotification('top','center','Quantité enregistré',1);
      
            }); 
            event.confirm.resolve();

     }
  




  showNotification(from, align, messagej,t){
   const type = ['','info','success','warning','danger'];

   const color = Math.floor((Math.random() * 4) + 1);

   $.notify({
       icon: "notifications",
       message: messagej

   },{
       type: type[t],
       timer: 4000,
       placement: {
           from: from,
           align: align
       },
       template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
         '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
         '<i class="material-icons" data-notify="icon">notifications</i> ' +
         '<span data-notify="title">{1}</span> ' +
         '<span data-notify="message">{2}</span>' +
         '<div class="progress" data-notify="progressbar">' +
           '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
         '</div>' +
         '<a href="{3}" target="{4}" data-notify="url"></a>' +
       '</div>'
   });
}




}
