import { Component, OnInit } from '@angular/core';
import { Http, Response } from '@angular/http';
import { ServiceService } from '../../service/service.service';
import PerfectScrollbar from 'perfect-scrollbar';

import { AuthService } from 'app/service/auth/auth.service';

declare const $: any;


//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    collapse?: string;
    type?: string;
    icontype?: string;
    children?: ChildrenItems2[];
}
export interface ChildrenItems2 {
    path?: string;
    title?: string;
    ab?: string;
    type?: string;
}


//Menu Items



export const ROUTES: RouteInfo[] = [{
        path: '/dashboard',
        title: 'Dashboard',
        type: 'link',
        icontype:  '../assets/img/dashboard (2).png'
    },{
        path: '/EffectuerVente',
        title: 'Effectuer une Vente',
        type: 'link',
        icontype: '../assets/img/basket.png'
    },{
        path: '/Gestionstock',
        title: 'Gestion du stock',
        type: 'link',
        icontype: '../assets/img/chart.png'
    },{
        path: '/EnregProduit',
        title: 'Enregistrer un produit',
        type: 'link',
        icontype: '../assets/img/education.png'
    },{
        path: '/ConsulterProduit',
        title: 'Consulter les produits',
        type: 'link',
        icontype: '../assets/img/consult.png'
    },{
        path: '/ModifierProduit',
        title: 'Modifier un produit',
        type: 'link',
        icontype: '../assets/img/modification.png'
    },{
        path: '/SupprimerProduit',
        title: 'Supprimer un produit',
        type: 'link',
        icontype: '../assets/img/delete.png'
    },{
        path: '/Imprimer',
        title: 'Inventaire et duplicata',
        type: 'link',
        icontype: '../assets/img/print.png'
    },
    
    
    
    {
        path: '/ModifPass',
        title: 'Modifier le mot de passe',
        type: 'link',
        icontype: '../assets/img/password.png'
    },{
        path: '/user-profile',
        title: 'Getion des utilisateurs',
        type: 'link',
        icontype: '../assets/img/group (1).png'
    },{
        path: '/Categorie',
        title: 'Créer une catégorie',
        type: 'link',
        icontype: '../assets/img/category.png'
    },{
        path: '/Parametre',
        title: 'Paramètres',
        type: 'link',
        icontype: '../assets/img/settings.png'
    },{
        path: '/Journal',
        title: 'Journal',
        type: 'link',
        icontype: '../assets/img/news.png'
    },{
        path: '/GetionInventaire',
        title: 'Gestion de L\'inventaire',
        type: 'link',
        icontype: '../assets/img/chest.png'
    },{
        path: '/AnnullerVente',
        title: 'Annuler une Vente',
        type: 'link',
        icontype: '../assets/img/exit.png'
    },{
        path: '/ImporterProduit',
        title: 'Importer les Produits',
        type: 'link',
        icontype: '../assets/img/import.png'
    }
    
];


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  providers: [AuthService],
})
export class SidebarComponent implements OnInit {

    ROUTES1: RouteInfo[] = [];
    public menuItems: any[];
    tableau= [];
    public tt=" ";
    constructor(private http: Http,private param: ServiceService,
        private authService: AuthService) { 



    }
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    ngOnInit() {


      /*   if(localStorage.getItem('user')==='dd'){
            this.ROUTES1.push({
                path: '/Gestionstock',
                title: 'Gestion du stock',
                type: 'link',
                icontype: 'storage'
            });

        }*/

        this.tableau=localStorage.getItem('user').split(";");
        for (var i=0;i<ROUTES.length;i++){ 

            if (this.tableau.includes(ROUTES[i].title)) {
                this.ROUTES1.push(ROUTES[i]);
              }

        }
       this.menuItems = this.ROUTES1.filter(menuItem => menuItem);




    }
    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
    getLogin() {
        return this.authService.getUser().login;
      }
    
      logout() {
        return this.authService.logout();
      }
    
      hasAnyRole(roles: string[]) {
        return this.authService.hasAnyRole(roles);
      }
    

}
