import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
//import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { ServiceService } from '../service/service.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
declare var $: any;

import { AuthService } from 'app/service/auth/auth.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [AuthService]
})
export class LoginComponent implements OnInit {
  items1: any[];
   items2: any[];
   items3: any[];
   items4: any[];
   items5: any[];
   tr: any=['Enregistrer un produit','Consulter les produits','Modifier un produit'];
   
  data2: any='/';
  TYPEUtilisateur: String="Utilisateur Stendart";
  langue: any='Française';
  public url1:any;

 bdname:any;
  private _jsonURL = '../assets/data/param.json';
  model: any = {};
  tableau= [];
  categorie: any;
  error:string;
  data3: any;
  data4: any;
  data5: any;
  data6: any;
  data7: any;
  public erreur =false;

  categorief: any;
  categorief1: any;






  
constructor(private http: Http, private router: Router,public param: ServiceService,private http1: HttpClient,private authService: AuthService) { 

  





  }








 
  ngOnInit() {

  }
  login() {
    this.authService.login(this.model);
  }
  recup(l:String,p:String) {
    this.url1=this.param.lien+'connexion.php?log='+l+'&pass='+p
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recupcon(l:String) {
    this.url1=this.param.lien+'add_connexion_journal.php?login='+l
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recupcategorie() {
    this.url1=this.param.lien+'recup_categorie.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recupinfopharma() {
    this.url1=this.param.lien+'recup_infoPharma.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recupinfopharmajjp() {
    this.url1=this.param.lien+'fcreer.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }









  recuphere(l:String,p:String){



    this.recup(l,p).subscribe((dataGet) =>{
      this.items2=dataGet; 

      

       

   
        if(this.items2.length===0){
    
          this.showNotification('top','center','login ou Mot de passe incorrect',4);
          }else{
  
            this.param.passok=true;
            
  
  
  
  
            localStorage.setItem('langue', this.langue);
            localStorage.setItem('login', this.items2[0].username);
            localStorage.setItem('pass', this.items2[0].password);
            localStorage.setItem('nom', this.items2[0].nomUser);
            localStorage.setItem('prenomUser', this.items2[0].prenomUser);
            localStorage.setItem('sexe', this.items2[0].sexe);
            localStorage.setItem('typeuser', this.items2[0].typeUser);
            localStorage.setItem('phoneNumber', this.items2[0].phoneNumber);
            localStorage.setItem('email', this.items2[0].email);
            localStorage.setItem('adressUser', this.items2[0].adressUser);
  
            
            localStorage.setItem('token', 'token');
              localStorage.setItem('isconn', 'true');
              localStorage.setItem('verif', 'true');
  
  
              localStorage.setItem('user', this.items2[0].taches);
              this.recupcon(this.items2[0].username).subscribe(
                data => {
                },
                    );
            //  this.tableau=localStorage.getItem('user').split(";");
             // console.log('eeeeeeeeeeeeeeeeee'+this.tableau.length);
  
  
  
              this.categorie  = []
              this.recupcategorie().subscribe(
                data => {
                  data.forEach(obj => {
                    this.categorie.push({value: obj.code,title: obj.nom_categorie}) ;                  
                  });
                  localStorage.setItem('categorie', JSON.stringify(this.categorie));
    
                },
                error => this.error = error.message
                    );
        
  
  
                    
                    this. recupinfopharma().subscribe(
                      data => {
                        data.forEach(obj => {
  
                          localStorage.setItem('nomPharma', obj.nom);
                          localStorage.setItem('bpPharma', obj.bp);
                          localStorage.setItem('villePharma', obj.ville); 
                          localStorage.setItem('telpharma', obj.tel);     
                          localStorage.setItem('TVA', obj.TVA);   
                          localStorage.setItem('region', obj.region);   
                          localStorage.setItem('aire_sante', obj.aire_sante);   
                          localStorage.setItem('district', obj.district);     
                        });
                      },
                      error => this.error = error.message
                          );
  
  
                          this.router.navigate(['Acceil']);
                        
                          //window.location.reload();
  
  
                       /*  this.router.navigateByUrl('/dashboard', { skipLocationChange: true }).then(() => {
                            this.router.navigate(['dashboard']);
                            window.location.reload();
                        }); */
                      
              
         
  
          }  
        



   
    }
  )

};

/*state(): boolean {
  let tru=true;
  let tr=localStorage.getItem('topo1');
if(tr==='false')tru=false;
 return tru;
 }*/




  onFormSubmit(userForm: NgForm) {

    this.recuphere(userForm.value.email,userForm.value.password);



  }

  recupinfopharmajjD() {
    this.url1=this.param.lien+'fpdf1BIS.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }

  recupinfopharmajj() {
    this.url1=this.param.lien+'fpdf1.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recupinfopharmaDT() {
    this.url1=this.param.lien+'fpdf3.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recupinfopharmajjj() {
    this.url1=this.param.lien+'fpdf2.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }

   onChange1(deviceValue1) {
    this.TYPEUtilisateur=deviceValue1;
   }
   onChange2(deviceValue2) {
    this.langue=deviceValue2;
   }




   showNotification(from, align, messagej,t){
    const type = ['','info','success','warning','danger'];

    const color = Math.floor((Math.random() * 4) + 1);

    $.notify({
        icon: "notifications",
        message: messagej

    },{
        type: type[t],
        timer: 4000,
        placement: {
            from: from,
            align: align
        },
        template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
}




}
