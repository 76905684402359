import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import {FormGroup} from '@angular/forms'
import { NgForm } from '@angular/forms';
//import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { Http, Response } from '@angular/http';
//import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ServiceService } from '../service/service.service';
declare var $: any;


@Component({
  selector: 'app-parametre',
  templateUrl: './parametre.component.html',
  styleUrls: ['./parametre.component.scss']
})



export class ParametreComponent implements OnInit {

    
  
  public message='';
  settings= {
   

  noDataMessage: "Aucun paramètre n'est enrégistré pour le moment!",
  add: {
    addButtonContent: `<img src="../assets/img/plus.png">`,
    createButtonContent: `<img src="../assets/img/checkmark.png">`,
    cancelButtonContent: `<img src="../assets/img/close.png">`,
    confirmCreate: true,
  },
  edit: {
    editButtonContent: `<img src="../assets/img/pencil.png">`,
    saveButtonContent: `<img src="../assets/img/checkmark.png">`,
    cancelButtonContent: `<img src="../assets/img/close.png">`,
    confirmSave: true,
  },
  delete: {
    deleteButtonContent: `<img src="../assets/img/trash.png">`,
    confirmDelete: true,
  },
  columns: {
    id: {
      title: 'ID',
      type: 'string',
      editable: false,
    },

    nom: {
     title: 'Nom',
     type: 'string',
   },
   bp: {
      title: 'Boite postal',
      type: 'string',
   
    },
    ville: {
     title: 'Ville',
     type: 'string',
  
   },
   tel: {
     title: 'Tel',
     type: 'string',
  
   },
   numeroContribuable: {
     title: 'Numéro contribuable',
     type: 'string',
  
   },
   Matricule: {
     title: 'Matricule',
     type: 'string',
  
   },
   TVA: {
     title: 'TVA',
     type: 'string',
  
   },
   region: {
     title: 'Région',
     type: 'string',
  
   },
   aire_sante: {
     title: 'Aire de santé',
     type: 'string',
  
   },
   district: {
     title: 'District',
     type: 'string',
  
   }



  },

  pager: {
    display: true,
    perPage: 7,
  },
  actions: {
   add: true,
    position: 'left',
  },
  attr: {
    class: 'table table-hover'
  }
};














nbusers = 0;
error:string;

public items1: any;
public url1:any;
messageF='';
tableau=[];

nomphar='';


profilePicture: string = null;
  constructor(private http: Http, private router: Router,public param: ServiceService,private http1: HttpClient) { 

  

   this.recupproduit().subscribe(
     data => {
       this.items1 = JSON.parse(JSON.stringify(data)); 
 

       this.nbusers = this.items1.length;
     },
   error => this.error = error.message
       );

     console.log(this.nomphar);
  }
  



  ngOnInit() {


    this.tableau=localStorage.getItem('user').split(";");
      if (!this.tableau.includes('Paramètres')) {
        this.router.navigate(['Acceil']);
        }



      }
      recupproduit() {
       this.url1=this.param.lien+'recup_infoPharma.php'
       return this.http
       .get(this.url1)
       .map((data: Response) => data.json())
     }
    


     
     onDeleteConfirm(event) {
 

       if (window.confirm('Êtes-vous sûr de vouloir supprimer cette pharmacie?')) {
        

         this.http.get(this.param.lien+'delete_infoParma.php?id='+event.data.id).subscribe(data => {
           this.showNotification('top','center','Suppression effectué',1);
              }); 
              event.confirm.resolve();
             } else {
         event.confirm.reject();
       }
     }
     onCreateConfirm(event) {
      this.http.get(this.param.lien+'add_infoPharma.php?nom='+event.newData.nom+'&bp='
      +event.newData.bp+'&ville='+event.newData.ville+'&numeroContribuable='+ event.newData.numeroContribuable
      +'&Matricule='+event.newData.Matricule+'&tel='+event.newData.tel+'&TVA='+event.newData.TVA
      +'&region='+event.newData.region+'&aire_sante='+event.newData.aire_sante+'&district='+event.newData.district).subscribe(data => {
        this.showNotification('top','center','Enregistrement effectué',1);

           }); 
         
           event.confirm.resolve();
    
    }
     onSaveConfirm(event) {


       this.http.get(this.param.lien+'modif_infoPharma.php?nom='+event.newData.nom+'&bp='
       +event.newData.bp+'&ville='+event.newData.ville+'&numeroContribuable='+ event.newData.numeroContribuable
       +'&Matricule='+event.newData.Matricule+'&tel='+event.newData.tel+'&TVA='+event.newData.TVA
       +'&region='+event.newData.region+'&aire_sante='+event.newData.aire_sante+'&district='+event.newData.district
       +'&id='+event.data.id).subscribe(data => {

         this.showNotification('top','center','Modification effectué',1);
      
            }); 
            event.confirm.resolve();

     }
  




  showNotification(from, align, messagej,t){
   const type = ['','info','success','warning','danger'];

   const color = Math.floor((Math.random() * 4) + 1);

   $.notify({
       icon: "notifications",
       message: messagej

   },{
       type: type[t],
       timer: 4000,
       placement: {
           from: from,
           align: align
       },
       template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
         '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
         '<i class="material-icons" data-notify="icon">notifications</i> ' +
         '<span data-notify="title">{1}</span> ' +
         '<span data-notify="message">{2}</span>' +
         '<div class="progress" data-notify="progressbar">' +
           '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
         '</div>' +
         '<a href="{3}" target="{4}" data-notify="url"></a>' +
       '</div>'
   });
}




}
