import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import {MatMenuModule} from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

import { Ng2SmartTableModule } from 'ng2-smart-table';

import { PdfJsViewerModule } from 'ng2-pdfjs-viewer'; // <-- Import PdfJsViewerModule module
import { ChartsModule } from 'ng2-charts';





import { LoginComponent } from './login/login.component';
import {
  AgmCoreModule
} from '@agm/core';
import {
  MatButtonModule,
  MatInputModule,
  MatRippleModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatSelectModule
} from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { CreerUserComponent } from './creer-user/creer-user.component';
import { CategorieComponent } from './categorie/categorie.component';
import { ModifPassComponent } from './modif-pass/modif-pass.component';
import { EnregProduitComponent } from './produit/enreg-produit/enreg-produit.component';
import { ConsulterProduitComponent } from './produit/consulter-produit/consulter-produit.component';
import { ModifierProduitComponent } from './produit/modifier-produit/modifier-produit.component';
import { SupprimerProduitComponent } from './produit/supprimer-produit/supprimer-produit.component';
import { EffectuerVenteComponent } from './vente/effectuer-vente/effectuer-vente.component';
import { ViewPdfComponent } from './vente/view-pdf/view-pdf.component';


import {  SafePipe } from './vente/effectuer-vente/effectuer-vente.component';
import { ParametreComponent } from './parametre/parametre.component';
import { GestionstockComponent } from './gestionstock/gestionstock.component';
import { DetailProduitComponent } from './detail-produit/detail-produit.component';

import { AuthService } from 'app/service/auth/auth.service';
import { AcceilComponent } from './acceil/acceil.component';
import { JournalComponent } from './journal/journal.component';
import { AppGlobals } from './service/APPGLOBAL.service';
import { ImprimerComponent } from './imprimer/imprimer.component';
import { GetionInventaireComponent } from './getion-inventaire/getion-inventaire.component';
import { AnnullerVenteComponent } from './annuller-vente/annuller-vente.component';
import { ImporterProduitComponent } from './importer-produit/importer-produit.component';
import { ProfileComponent } from './profile/profile.component';
import { ApropoComponent } from './apropo/apropo.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    Ng2SmartTableModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    MatMenuModule,
    PdfJsViewerModule,
    ChartsModule,
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    })
  ],
  declarations: [
    DashboardComponent,
    UserProfileComponent,
    LoginComponent,
    AppComponent,
    CreerUserComponent,
    CategorieComponent,
    ModifPassComponent,
    EnregProduitComponent,
    ConsulterProduitComponent,
    ModifierProduitComponent,
    SupprimerProduitComponent,
    EffectuerVenteComponent,
    ViewPdfComponent,
    SafePipe,
    ParametreComponent,
    GestionstockComponent,
    DetailProduitComponent,
    AcceilComponent,
    JournalComponent,
    ImprimerComponent,
    GetionInventaireComponent,
    AnnullerVenteComponent,
    ImporterProduitComponent,
    ProfileComponent,
    ApropoComponent,
  ],

  providers: [AuthService,AppGlobals],
  bootstrap: [AppComponent]
})
export class AppModule { }
