import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import {FormGroup} from '@angular/forms'
import { NgForm } from '@angular/forms';
//import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { Http, Response } from '@angular/http';
//import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ServiceService } from '../../service/service.service';
declare var $: any;

@Component({
  selector: 'app-enreg-produit',
  templateUrl: './enreg-produit.component.html',
  styleUrls: ['./enreg-produit.component.scss'],
  providers: [DatePipe]
})
export class EnregProduitComponent implements OnInit {

  nbusers = 0;
  error:string;
 
  public items1=[];
    tache1=[];
    tache="";
    menu="";
    public url1:any;
    tableau= [];
    nomuser="";
    Prenomuser="";
    dateNaisUser="";
    phonenumber="";
    email="";
    adressUser="";
    public categorie= [];
    login1="";
    password1="";

    categories: String="choisir la Catégorie ici !!!";
    profilpicture='../assets/img/photos_utilisateur/face-3.jpg';
  
    constructor(private http: Http,public param: ServiceService,private datePipe: DatePipe, private router: Router) {
  
      this.categorie  = []
    this.recupcategorie().subscribe(
      data => {
        this.categorie = JSON.parse(JSON.stringify(data)); 
      },
        );
  

     }






    ngOnInit() {

      this.tableau=localStorage.getItem('user').split(";");
      if (!this.tableau.includes('Enregistrer un produit')) {
        this.router.navigate(['Acceil']);
        }
    }



    recupproduit() {
      this.url1=this.param.lien+'recup_produitCONSULT.php'
      return this.http
      .get(this.url1)
      .map((data: Response) => data.json())
    }
   
     selectcategori(deviceValue2) {

    

      this.categories=deviceValue2;
     }
  
     recupcategorie() {
      this.url1=this.param.lien+'recup_categorie.php'
      return this.http
      .get(this.url1)
      .map((data: Response) => data.json())
    }




    onFormSubmit(userForm: NgForm) {
  
      this.recupproduit().subscribe(
        data => {
          this.items1 = JSON.parse(JSON.stringify(data)); 
          this.nbusers = this.items1.length;

          let bnvv=0;
          for (var i=0;i<this.items1.length;i++){ 
            if(+this.items1[i].cont> bnvv){ 
              bnvv=this.items1[i].cont;
            }
          }
          console.log(bnvv);
         let id='MEDOC'+ (bnvv+2);
         console.log(id);

          var g=0;                           
          for (var i=0;i<this.items1.length;i++){ 
       if( id===this.items1[i].id){ 
       g=1;
       break;}
         }
   


         if(userForm.value.Nom_C.length<=0){ 
          this.showNotification('top','center','Le nom du produit est obligatoire!',4);
        }else if(g===1){
           this.showNotification('top','center','Ce produit existe déjà!',4);
         }else{
   
           var d= this.nbusers;
           var dr=+d;
      
        
   
         this.http.get(this.param.lien+'add_produit.php?Nom_commercial='
         +userForm.value.Nom_C+'&Dosage='+userForm.value.Dosage+'&Conditionnement='+userForm.value.Condi
         +'&id_categorie='+ this.categories+'&Forme='+ userForm.value.Forme+'&Prix_achat='+ userForm.value.PPAA
         +'&id='+id+'&login='+ localStorage.getItem('login')).subscribe(data => {
   
   
   
           this.showNotification('top','center','Enregistrement effectué',1);
              }); 
   
             }
              



        },
          );


    }
  
  
  
           readUrl(event:any) {
            if (event.target.files && event.target.files[0]) {
                var reader = new FileReader();
      
                reader.onload = (event:any) => {
                    this.profilpicture= event.target.result;
                    console.log(this.profilpicture);
                }
      
                reader.readAsDataURL(event.target.files[0]);
            }
       }
    showNotification(from, align, messagej,t){
      const type = ['','info','success','warning','danger'];
  
      const color = Math.floor((Math.random() * 4) + 1);
  
      $.notify({
          icon: "notifications",
          message: messagej
  
      },{
          type: type[t],
          timer: 4000,
          placement: {
              from: from,
              align: align
          },
          template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
            '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
            '<i class="material-icons" data-notify="icon">notifications</i> ' +
            '<span data-notify="title">{1}</span> ' +
            '<span data-notify="message">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            '</div>' +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
          '</div>'
      });
  }
  
  
  }
  