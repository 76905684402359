import { Component, OnInit } from '@angular/core';

import {FormGroup} from '@angular/forms'
import { NgForm } from '@angular/forms';
//import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { Http, Response } from '@angular/http';
//import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ServiceService } from '../service/service.service';
declare var $: any;


@Component({
  selector: 'app-creer-user',
  templateUrl: './creer-user.component.html',
  styleUrls: ['./creer-user.component.scss']
})
export class CreerUserComponent implements OnInit {

  settings= {
         
     
    noDataMessage: "Aucun produit n'est enrégistré pour le moment!",
    add: {
      addButtonContent: `<img src="../assets/img/plus.png">`,
      createButtonContent: `<img src="../assets/img/checkmark.png">`,
      cancelButtonContent: `<img src="../assets/img/close.png">`,
      confirmCreate: false,
    },
    edit: {
      editButtonContent: `<img src="../assets/img/pencil.png">`,
      saveButtonContent: `<img src="../assets/img/checkmark.png">`,
      cancelButtonContent: `<img src="../assets/img/close.png">`,
      confirmSave: false,
    },
    delete: {
      deleteButtonContent: `<img src="../assets/img/trash.png">`,
      confirmDelete: false,
    },
    hideSubHeader:true,
  
    columns: {

     
      tache: {
        title: 'Tache',
        type: 'string',
        editable: false,
      }

    },
    pager: {
      display: true,
      perPage: 10,
    },
    selectMode: 'multi',
    actions: false,
  
    attr: {
      class: 'table table-hover'
    }
  };
 

  public items22=[{tache:'Dashboard'},{tache:'Getion des utilisateurs'},{tache:'Modifier le mot de passe'}
  ,{tache:'Créer une catégorie'},{tache:'Enregistrer un produit'}
   ,{tache:'Consulter les produits'},{tache:'Modifier un produit'},{tache:'Supprimer un produit'},
   {tache:'Effectuer une Vente'},{tache:'Paramètres'},{tache:'Gestion du stock'},{tache:'Journal'},{tache:'Inventaire et duplicata'},
   {tache:'Annuler une Vente'},{tache:'Importer les Produits'}];

  
   

  tache1=[];
  items1=[];
  menu="";

  public url1:any;
  nomuser="";
  Prenomuser="";
  dateNaisUser="";
  phonenumber="";
  email="";
  adressUser="";
  taches='';
  login1="";
  password1="";
  typeuser: String="utilisateur";
  sexe: String="Feminin";
  profilpicture='../assets/img/photos_utilisateur/face-3.png';
  tableau= [];
  constructor(private http: Http,public param: ServiceService, private router: Router) {


//  this.tache1.push('Dashboard');

   }
  tr:string;
  ngOnInit() {

    this.tableau=localStorage.getItem('user').split(";");
    if (!this.tableau.includes('Getion des utilisateurs')) {
      this.router.navigate(['Acceil']);
      }
  }
  onChange1(deviceValue1) {
    this.typeuser=deviceValue1;
   }
   onChange2(deviceValue2) {
    this.sexe=deviceValue2;
   }
   onrowSelect(event){
        

    var h=0;
    for (var i=0;i<this.tache1.length;i++){  
      if(event.data.tache===this.tache1[i]){ 
        h=1;
        this.tache1.splice(i, 1) ;
        break;
      }
    }
    if(h===0){
      this.tache1.push(event.data.tache);
     
    }else{
  
    }
          }

          recupuser() {
            this.url1=this.param.lien+'recup_user.php'
            return this.http
            .get(this.url1)
            .map((data: Response) => data.json())
          }




          onFormSubmit(userForm: NgForm) {

            this.recupuser().subscribe(
              data => {
                this.items1 = JSON.parse(JSON.stringify(data)); 
                var p=0;
                for (var i=0;i<this.items1.length;i++){ 
                  if(userForm.value.login1===this.items1[i].username){
                    p=1;
                    break;
                   }
                  }
                  if(p==1){
                    this.showNotification('top','center','Ce login existe déjà',4);
                   }else{
                    
                    for (var i=0;i<this.tache1.length;i++){  
                      if(i==this.tache1.length-1){
                        this.taches=this.taches+this.tache1[i];
                       }else{
                         this.taches=this.taches+this.tache1[i]+";";
                       }
                    }
            console.log(this.tache1);
        
            console.log(userForm.value.login1);
          
            this.login1=userForm.value.login1;
            this.password1=userForm.value.password1;
            this.nomuser=userForm.value.nomuser;
            this.phonenumber=userForm.value.phonenumber;
        
            this.nomuser=userForm.value.nomuser;
            this.Prenomuser=userForm.value.Prenomuser;
            this.dateNaisUser=userForm.value.dateNaisUser;
            this.phonenumber=userForm.value.phonenumber;
            this.email=userForm.value.email;
            this.adressUser=userForm.value.adressUser;
        
        
          
             
                                            
        
            this.http.get(this.param.lien+'add_user.php?typeuser='+this.typeuser+'&sexe='
            +this.sexe+'&login1='+this.login1+'&password1='+ this.password1+'&nomuser='+this.nomuser
            +'&phonenumber='+this.phonenumber+'&nomuser='+this.nomuser+'&Prenomuser='+this.Prenomuser
            +'&dateNaisUser='+this.dateNaisUser+'&phonenumber='+ this.phonenumber+'&email='+ this.email
            +'&adressUser='+this.adressUser+'&taches='+this.taches+'&login='+ localStorage.getItem('login')).subscribe(data => {
              this.showNotification('top','center','Enregistrement effectué',1);
                 }); 

                   }
              },
                );

     





  }

  

         readUrl(event:any) {
          if (event.target.files && event.target.files[0]) {
              var reader = new FileReader();
    
              reader.onload = (event:any) => {
                  this.profilpicture= event.target.result;
                  console.log(this.profilpicture);
              }
    
              reader.readAsDataURL(event.target.files[0]);
          }
     }
  showNotification(from, align, messagej,t){
    const type = ['','info','success','warning','danger'];

    const color = Math.floor((Math.random() * 4) + 1);

    $.notify({
        icon: "notifications",
        message: messagej

    },{
        type: type[t],
        timer: 4000,
        placement: {
            from: from,
            align: align
        },
        template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
}


}
