import { Component, OnInit } from '@angular/core';
import * as Chartist from 'chartist';

import { DatePipe } from '@angular/common';
import {FormGroup} from '@angular/forms'
import { NgForm } from '@angular/forms';
//import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { AppGlobals } from '../service/APPGLOBAL.service';
import { Http, Response } from '@angular/http';
//import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ServiceService } from '../service/service.service';
import * as jsPDF from 'jspdf'
import 'jspdf-autotable';

import 'jspdf';
//declare let jsPDF;


declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DatePipe],
 
})
export class DashboardComponent implements OnInit {
  public message='';


  columns: {
    N_lot:{
      title: 'ID'
    },
    sqt: {
       title: 'Nom patient'
     },
     sst:{
       title:'Prenom'
     },
     Nom_commercial: {
       title: 'Ville'
     },
     prix_tax: {
       title: 'Résidence'
     },
     datefacruration: {
       title: 'type',
     }
     
   }


  settings1= {
   

    noDataMessage: "Aucunne vente enregistrée!",
    add: {
      addButtonContent: `<img src="../assets/img/plus.png">`,
      createButtonContent: `<img src="../assets/img/checkmark.png">`,
      cancelButtonContent: `<img src="../assets/img/close.png">`,
      confirmCreate: true,
    },
    edit: {
      editButtonContent: `<img src="../assets/img/pencil.png">`,
      saveButtonContent: `<img src="../assets/img/checkmark.png">`,
      cancelButtonContent: `<img src="../assets/img/close.png">`,
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: `<img src="../assets/img/trash.png">`,
      confirmDelete: true,
    },
    columns: {
      id: {
        title: 'ID',
        type: 'string',
        editable: false,
      },
      Nom_commercial: {
        title: 'Désignation/DCI',
        type: 'string',
     
      },
      Dosage: {
        title: 'Dosage',
        type: 'string',
        editable: false,
        width:'10%',
      },
      forme: {
        title: 'Forme',
        type: 'string',
        editable: false,
      }, 
     N_lot: {
       title: 'Numéro de lot',
       type: 'string',
       editable: false,
     },
      sqt: {
       title: 'Qt vendu',
       type: 'string',
     },
     prix_tax: {
       title: 'Prix unitaire',
       type: 'string',
     },
     sst: {
       title: 'Total',
       type: 'string',
     }
  
    },
  
  
    pager: {
      display: true,
      perPage: 7,
    },
    actions: {
      delete: false,
      add: false,
      edit: false,
      select: false,
      title: 'Detail',
      custom: [{  name: 'routeToAPage', title: `<img src="../assets/img/ico.png">` }]
    },
    attr: {
      class: 'table table-hover'
    }
  };




  settings= {
   

  noDataMessage: "Aucun produit n'est enrégistré pour le moment!",
  add: {
    addButtonContent: `<img src="../assets/img/plus.png">`,
    createButtonContent: `<img src="../assets/img/checkmark.png">`,
    cancelButtonContent: `<img src="../assets/img/close.png">`,
    confirmCreate: true,
  },
  edit: {
    editButtonContent: `<img src="../assets/img/pencil.png">`,
    saveButtonContent: `<img src="../assets/img/checkmark.png">`,
    cancelButtonContent: `<img src="../assets/img/close.png">`,
    confirmSave: true,
  },
  delete: {
    deleteButtonContent: `<img src="../assets/img/trash.png">`,
    confirmDelete: true,
  },
  columns: {
    Nom_commercial: {
      title: 'Désignation/DCI',
      type: 'string',
   
    },
    n_lot: {
     title: 'Numéro de lot',
     type: 'string',
     editable: false,
   },
    id_categorie: {
     title: 'Catégorie',
     type: 'string',
  
   },
   Quantite: {
     title: 'Stock',
     type: 'string',
  
   }


  },


  pager: {
    display: true,
    perPage: 7,
  },
  actions: false,
  attr: {
    class: 'table table-hover'
  }
};


settings9= {
   

  noDataMessage: "Aucun produit n'est enrégistré pour le moment!",
  add: {
    addButtonContent: `<img src="../assets/img/plus.png">`,
    createButtonContent: `<img src="../assets/img/checkmark.png">`,
    cancelButtonContent: `<img src="../assets/img/close.png">`,
    confirmCreate: true,
  },
  edit: {
    editButtonContent: `<img src="../assets/img/pencil.png">`,
    saveButtonContent: `<img src="../assets/img/checkmark.png">`,
    cancelButtonContent: `<img src="../assets/img/close.png">`,
    confirmSave: true,
  },
  delete: {
    deleteButtonContent: `<img src="../assets/img/trash.png">`,
    confirmDelete: true,
  },
  columns: {
    id: {
      title: 'ID',
      type: 'string',
      editable: false,
    },
    Nom_commercial: {
      title: 'Désignation/DCI',
      type: 'string',
   
    },
    Dosage: {
      title: 'Dosage',
      type: 'string',
      editable: false,
      width:'10%',
    },
    forme: {
      title: 'Forme',
      type: 'string',
      editable: false,
    }, 
    n_lot: {
     title: 'Numéro de lot',
     type: 'string',
     editable: false,
   },

    dateappro: {
     title: 'Date approvisionnement',
     type: 'string',
     editable: false,
   },
   Quantite: {
     title: 'Quantité',
     type: 'string',
     editable: false,
   },
   Prix_populaire: {
    title: 'Prix',
    type: 'string',
    editable: false,
  }

  },


  pager: {
    display: true,
    perPage: 7,
  },
  actions: false,
  attr: {
    class: 'table table-hover'
  }
};




   dataRecup1=[];
   dataRecup=[];
  public url1:any;
  public categorie= [];
  public categorieC:any;
  tab_catego= [];
  tab_qt:number[];
  public items9: any[];
  public items1: any[];
  public items11: any[];
  public items33: any[];
  public items44: any[];
  public items55: any[];
  messageV='LISTE DES VENTES DU JOUR';
  messageF='';
  nbusers = 0;
  nbusers9 = 0;
  tableau= [];
  venteTotal = 0;
  venteTotalsemaine = 0;
  venteTotalmoi = 0;
  venteTotalanne = 0;
  venteTotal2 = 0;
  venteTotal1j = '';
  venteTotal1 = '';
  venteTotalsemaine1 = '';
  venteTotalmoi1 = '';
  venteTotalanne1 = '';
  venteTotal21 = '';

  dateD='';
  dateF='';

  public barChartOptions:any = {
    scaleShowVerticalLines: true,
    responsive: true,
    scales: { xAxes: ['ffff'], yAxes: ['ttttt'] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
  }
}
public barChartType:string = 'bar';
public barChartLegend:boolean = true;
public barChartData:any[];
public barChartLabels:string[] ;




  constructor(private gpdf: AppGlobals,private http: Http,public param: ServiceService,private datePipe: DatePipe, private router: Router) {

    this.recupproduit().subscribe(
      data => {
        this.items1 = JSON.parse(JSON.stringify(data)); 
        this.nbusers = this.items1.length;
      },
        );

        this.recupaprovisionnement().subscribe(
          data => {
            this.items9 = JSON.parse(JSON.stringify(data)); 
            this.nbusers9 = this.items9.length;
          },
            );

        this.recupvente().subscribe(
          data => {
            this.items11 = JSON.parse(JSON.stringify(data)); 
            localStorage.removeItem('items11');
            localStorage.setItem('items11', JSON.stringify(this.items11));
            for (var j=0;j<this.items11.length;j++){ 
              let qtv1=this.items11[j].sst;
              let f=+qtv1;
              this.venteTotal =this.venteTotal+f;
            


            }
            this.venteTotal1j = this.venteTotal.toFixed(2)+'';
            this.venteTotal1 = this.venteTotal.toFixed(2)+'';
          },
            );
            
            this.recup_vente_semaine().subscribe(
              data => {
                this.items33 = JSON.parse(JSON.stringify(data)); 
                for (var j=0;j<this.items33.length;j++){ 
                  let qtv1=this.items33[j].sst;
                  let f=+qtv1;
                  this.venteTotalsemaine =this.venteTotalsemaine+f;
                }
                this.venteTotalsemaine1 =this.venteTotalsemaine.toFixed(2)+'';
    
              },
                );

                this.recup_vente_moi().subscribe(
                  data => {
                    this.items44 = JSON.parse(JSON.stringify(data)); 
                    for (var j=0;j<this.items44.length;j++){ 
                      let qtv1=this.items44[j].sst;
                      let f=+qtv1;
                      this.venteTotalmoi =this.venteTotalmoi+f;
                    }
                    this.venteTotalmoi1 =this.venteTotalmoi.toFixed(2)+'';
        
                  },
                    );
                    this.recup_vente_anne().subscribe(
                      data => {
                        this.items55 = JSON.parse(JSON.stringify(data)); 
                        for (var j=0;j<this.items55.length;j++){ 
                          let qtv1=this.items55[j].sst;
                          let f=+qtv1;
                          this.venteTotalanne =this.venteTotalanne+f;
                        }
                        this.venteTotalanne1 =this.venteTotalanne.toFixed(2)+'';
            
                      },
                        );
                
   }













   recupproduit() {
    this.url1=this.param.lien+'recup_produit_VENTE.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recupaprovisionnement() {
    this.url1=this.param.lien+'recup_approvisionnement.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recupvente() {
    this.url1=this.param.lien+'recup_vente.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recup_vente_semaine() {
    this.url1=this.param.lien+'recup_vente_semaine.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recup_vente_moi() {
    this.url1=this.param.lien+'recup_vente_moi.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recup_vente_anne() {
    this.url1=this.param.lien+'recup_vente_anne.php?login='+ localStorage.getItem('login')
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recupvente2date(DD:string,DF:string) {
    this.url1=this.param.lien+'recup_vente_2date.php?dateD='+DD+'&dateF='+DF
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recupcategorie() {
    this.url1=this.param.lien+'recup_categorie.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recup_qt_categorie(code:string) {
    this.url1=this.param.lien+'recup_qt_categorie.php?code='+code
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }

  ngOnInit() {


    this.tableau=localStorage.getItem('user').split(";");
    if (!this.tableau.includes('Dashboard')) {
      this.router.navigate(['Acceil']);
      }
  


/* ----------==========     qt par catégorie    ==========---------- */
    this.barChartData= [];
    this.barChartLabels = [];
      this.categorie  = []
      this.categorieC  = []
    this.recupcategorie().subscribe(
      data => {
        this.categorie = JSON.parse(JSON.stringify(data)); 



         this.tab_catego= [];
        this.tab_qt = [];
         
        for (var j=0;j<this.categorie.length;j++){ 
          var qt= [];
          let qtv=0;
          console.log(this.categorie[j].code);

         
          this.tab_catego.push(''+this.categorie[j].code+'');
         //this.tab_catego[j]=this.categorie[j].code;
         // console.log("------"+this.tab_catego[j]);
        // this.tab_catego.push('r');
        this.recup_qt_categorie(this.categorie[j].code).subscribe(
          data => {
            qt = JSON.parse(JSON.stringify(data)); 
           
            for (var i=0;i<qt.length;i++){
              let qtv1=qt[i].Quantite;
              let f=+qtv1;
              qtv=qtv+f;

            }
            this.tab_qt.push(qtv);
 
            
          },
            );

            this.categorieC.push({code1: this.categorie[j].code,nom_categorie: this.categorie[j].nom_categorie,c:true}) ; 
          /*  var xx= localStorage.getItem('qt89');
            var xx1=+xx;
            console.log("ffff "+xx1)
            if(xx1<50){
              this.categorieC.push({code1: this.categorie[j].code,nom_categorie: this.categorie[j].nom_categorie,c:true}) ; 
            }else{
              this.categorieC.push({code1: this.categorie[j].code,nom_categorie: this.categorie[j].nom_categorie,c:false}) ; 
            }*/

          }
         

         // this.categorieC1 = JSON.stringify(this.categorieC); 
          console.log(this.tab_qt);

         // console.log(this.tab_catego);
         // console.log(this.tab_qt);
         // this.tab_catego= ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
      /* ----------==========     Daily Sales Chart initialization For Documentation    ==========---------- */
     
      this.barChartLabels = this.tab_catego;
      this.barChartData= [
        {data: this.tab_qt, label: 'Quantité des produits par catégorie',
        backgroundColor: '#e4e4e4f3',
        textColor: '#e4e4e4f3',
        borderColor: 'purple',
        hoverBackgroundColor: 'purple',
        hoverBorderColor: 'red',
        }
      ];

      },
    );


      /* ----------==========     LISTE DES VENTES DU JOUR    ==========---------- */






  }
  onFormSubmit(userForm: NgForm) {
//console.log(this.datePipe.transform(userForm.value.dated, 'yyyy-MM-dd'));
//console.log(this.datePipe.transform(userForm.value.datef, 'yyyy-MM-dd'));
this.dateD=this.datePipe.transform(userForm.value.dated, 'yyyy-MM-dd');
this.dateF=this.datePipe.transform(userForm.value.datef, 'yyyy-MM-dd');
this.messageV='LISTE DES VENTES DU '+this.datePipe.transform(userForm.value.dated, 'dd/MM/yyyy')+' AU '+this.datePipe.transform(userForm.value.datef, 'dd/MM/yyyy');
this.venteTotal=0;
        this.recupvente2date(this.datePipe.transform(userForm.value.dated, 'yyyy-MM-dd'),this.datePipe.transform(userForm.value.datef, 'yyyy-MM-dd')).subscribe(
          data => {
            this.venteTotal1j ='';
            this.items11 = JSON.parse(JSON.stringify(data)); 
            localStorage.removeItem('items11');
            localStorage.setItem('items11', JSON.stringify(this.items11));
            for (var j=0;j<this.items11.length;j++){ 
              let qtv1=this.items11[j].sst;
              let f=+qtv1;
              this.venteTotal =this.venteTotal+f;
            }
            this.venteTotal1j = this.venteTotal.toFixed(2)+'';

          },
            );


    
  }
  showNotification(from, align, messagej,t){
    const type = ['','info','success','warning','danger'];

    const color = Math.floor((Math.random() * 4) + 1);

    $.notify({
        icon: "notifications",
        message: messagej

    },{
        type: type[t],
        timer: 4000,
        placement: {
            from: from,
            align: align
        },
        template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
}
  onChange2(deviceValue2) {
    this.barChartType=deviceValue2;
   }


   Custom(event){

 localStorage.setItem('N_lot_detail', event.data.N_lot);
 localStorage.setItem('nom_produit', event.data.Nom_commercial);
 localStorage.setItem('dateD', this.dateD);
 localStorage.setItem('dateF', this.dateF);
 localStorage.setItem('idpro', event.data.id);

 this.router.navigate(['DetailProduit']);


           }
















  export(){
    // Col spans and row spans
    var doc = new jsPDF('L', 'pt');
    doc.setFontSize(12);
    doc.setTextColor(0);
    doc.setFontStyle('bold');
    doc.text('REPUBLIQUE DU CAMEROUN', 100, 50);
    doc.text('REPUBLIC OF CAMEROON', 600, 50);
    doc.text('PAIX-TRAVAIL-PATRIE', 120, 70);
    doc.text('PEACE-WORK-FATHERLAND', 590, 70);
    doc.text('-----------------', 140, 85);
    doc.text('-----------------', 640, 85);
    doc.text('MINISTERE DE LA SANTE PUBLIQUE', 100, 105);
    doc.text('MINISTRY OF PUBLIC HEALTH', 590, 105);
    doc.setFontSize(14);
    doc.text('QUANTITE DETAILLE DES PRODUITS', 330, 130);
    doc.setFontSize(12);
    doc.setFontStyle('italic');


    doc.text('Région: '+localStorage.getItem('region'), 50, 170);
    doc.text('Aire de santé: '+localStorage.getItem('aire_sante'), 300, 170);
    doc.text('Date:____________', 600, 170);

    doc.text('District de santé: '+localStorage.getItem('district'), 50, 200);
    doc.text('Nom de la structure: '+localStorage.getItem('nomPharma'), 300, 200);


    var img = new Image();
    img.src = 'assets/img/Farmacia1.png';
    doc.addImage(img, 'png', 400, 20, 80, 80);

    for (var i = 0; i < this.dataRecup.length; i++) {
        var row = this.dataRecup[i];
        if (i % 5 === 0) {
        row['id'] = {rowSpan: 14, content: i / 5 + 1, styles: {valign: 'middle', halign: 'center'}};
        }
    }
    let head = [
    [
        {content: 'N°', colSpan: 1, styles: {halign: 'center'}},
        {content: 'DESIGNATION', colSpan: 1, styles: {halign: 'center'}},
        {content: 'DOSAGE', colSpan: 1, styles: {halign: 'center'}},
        {content: 'FORME', colSpan: 1, styles: {halign: 'center'}},
        {content: 'CONDITIONNEMENT', colSpan: 1, styles: {halign: 'center',}},
        {content: 'N° DE LOT', colSpan: 1, styles: {halign: 'center',}},
        {content: 'PEREMTION', colSpan: 1, styles: {halign: 'center',}},
        {content: 'QUANTITE', colSpan: 1, styles: {halign: 'center',}},
        {content: 'PRIX UNITAIRE', colSpan: 1, styles: {halign: 'center',}},
        {content: 'CATEGORIE', colSpan: 1, styles: {halign: 'center',}},
    ]
    ]
    doc.autoTable({
      headerStyles: {
        lineWidth: 1,
        lineColor: [110,110,110 ],
        fillColor: [183,183,183 ],
        textColor: "#000000"
    },
    startY: 220,
    head: head,
    body: this.dataRecup,
    theme: 'grid'
    });
    doc.save('LISTE_DES_PRODUITS.pdf');
    }







    bodyRowsPRODUI_DETAIL() {
      this.dataRecup=[];
        let body = [];
        var itemNew = []
        var TRD = []
        TRD=  JSON.parse(localStorage.getItem('PRODUITINVENTAIRE'));

        this.recupproduit().subscribe(
          data => {
            this.items1 = JSON.parse(JSON.stringify(data));
            
            for (var i=0;i< this.items1.length;i++){


              let ss=0;
              for (var J=0;J< TRD.length;J++){
          if(TRD[J].id_produit===this.items1[i].id){
            ss=TRD[J].Q_N_U;
          }
              }
             
    
      

     

              let x = this.items1[i].Prix_populaire;
              let xL =  localStorage.getItem('TVA');;
              let y = +x;
              let yL = +xL;
              let tax=((y*yL)/100)+y;

  
             
               


  
      
           
    
             
            itemNew.push({N:(i+1),nom:  this.items1[i].Nom_commercial,dosage: this.items1[i].Dosage,
              forme:this.items1[i].forme,conditionnemt:  this.items1[i].Conditionnement,n_l: this.items1[i].n_lot,
              d_p:this.items1[i].Date_expiration,Q_S:  this.items1[i].Quantite,prix_u: tax,CATEGORIE:this.items1[i].id_categorie }) ; 
          }
         

    
          itemNew.forEach(element => {      
            var temp = [element.N,element.nom,element.dosage,element.forme,element.conditionnemt,element.n_l
              ,element.d_p,element.Q_S,element.prix_u,element.CATEGORIE];
            body.push(temp);

    
        });   

        console.log("bbbbbbbbbbbbbbbbbbbbb"+body);
        // localStorage.setItem('categorie', JSON.stringify(this.categorie));
        this.dataRecup=body;
       this.export(); 
          },
            );

            console.log("popoooooooooooooooooooo"+this.dataRecup);
        
        

        
   return 1;
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////




export1(){
  // Col spans and row spans
  var doc = new jsPDF('L', 'pt');
  doc.setFontSize(12);
  doc.setTextColor(0);
  doc.setFontStyle('bold');
  doc.text('REPUBLIQUE DU CAMEROUN', 100, 50);
  doc.text('REPUBLIC OF CAMEROON', 600, 50);
  doc.text('PAIX-TRAVAIL-PATRIE', 120, 70);
  doc.text('PEACE-WORK-FATHERLAND', 590, 70);
  doc.text('-----------------', 140, 85);
  doc.text('-----------------', 640, 85);
  doc.text('MINISTERE DE LA SANTE PUBLIQUE', 100, 105);
  doc.text('MINISTRY OF PUBLIC HEALTH', 590, 105);
  doc.setFontSize(14);
  doc.text('LISTE DES VENTES', 360, 130);
  doc.setFontSize(12);
  doc.setFontStyle('italic');

    doc.text('Région: '+localStorage.getItem('region'), 50, 170);
    doc.text('Aire de santé: '+localStorage.getItem('aire_sante'), 300, 170);
    doc.text('Date:____________', 600, 170);

    doc.text('District de santé: '+localStorage.getItem('district'), 50, 200);
    doc.text('Nom de la structure: '+localStorage.getItem('nomPharma'), 300, 200);


  var img = new Image();
  img.src = 'assets/img/Farmacia1.png';
  doc.addImage(img, 'png', 400, 20, 80, 80);

  for (var i = 0; i < this.dataRecup1.length; i++) {
      var row = this.dataRecup1[i];
      if (i % 5 === 0) {
      row['id'] = {rowSpan: 14, content: i / 5 + 1, styles: {valign: 'middle', halign: 'center'}};
      }
  }
  let head = [
  [
      {content: 'N°', colSpan: 1, styles: {halign: 'center'}},
      {content: 'N° DE LOT', colSpan: 1, styles: {halign: 'center',}},
      {content: 'DESIGNATION', colSpan: 1, styles: {halign: 'center'}},
      {content: 'QUANTITE VENDU', colSpan: 1, styles: {halign: 'center',}},
      {content: 'PRIX UNITAIRE', colSpan: 1, styles: {halign: 'center',}},
      {content: 'TOTAL', colSpan: 1, styles: {halign: 'center'}},
  ]
  ]
  doc.autoTable({
    headerStyles: {
      lineWidth: 1,
      lineColor: [110,110,110 ],
      fillColor: [183,183,183 ],
      textColor: "#000000"
  },
  startY: 220,
  head: head,
  body: this.dataRecup1,
  theme: 'grid'
  });
  doc.save('LISTE_DES_VENTES.pdf');
  }







  bodyRowsVENTE() {




    /*let items11p  = JSON.parse(JSON.stringify(localStorage.getItem('items11')));
   console.log(items11p);*/
    this.dataRecup1=[];
      let body = [];
      var itemNew = [];


           let items11p = [];
           items11p  = JSON.parse(localStorage.getItem('items11'));




           console.log(JSON.parse(localStorage.getItem('items11')));
          for (var i=0;i< items11p.length;i++){
            let xL =  items11p[i].sqt;
            let xL1 =  items11p[i].prix_tax;
            let y = +xL;
            let yL = +xL1;
            let total=y*yL;


          itemNew.push({N:(i+1),N_lot:  items11p[i].N_lot,Nom_commercial:items11p[i].Nom_commercial,
            sqt:items11p[i].sqt,prix_tax:  items11p[i].prix_tax,total: total.toFixed(2) }) ; 
          }
       

  
        itemNew.forEach(element => {      
          var temp = [element.N,element.N_lot,element.Nom_commercial,element.sqt,element.prix_tax,element.total];
          body.push(temp);
      });   
      this.dataRecup1=body;
     this.export1(); 
      
 return 1;


}





}
