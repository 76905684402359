import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
//import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { ServiceService } from '../service/service.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
declare var $: any;



@Component({
  selector: 'app-modif-pass',
  templateUrl: './modif-pass.component.html',
  styleUrls: ['./modif-pass.component.scss']
})
export class ModifPassComponent implements OnInit {

  tableau= [];
   utilisateur='';

    public erreur =false;
  constructor(private http: Http, private router: Router,public param: ServiceService,private http1: HttpClient) { 
  
    
    this.utilisateur=localStorage.getItem('login');
  
  
  
  
    }
 
    ngOnInit() {
      this.tableau=localStorage.getItem('user').split(";");
      if (!this.tableau.includes('Modifier le mot de passe')) {
        this.router.navigate(['Acceil']);
        }
    }
  
  
  
  
  
  
  
  
  

  
    onFormSubmit(userForm: NgForm) {



       if(userForm.value.password===localStorage.getItem('pass')){
        this.http.get(this.param.lien+'Modif_pass.php?login='+userForm.value.email+'&password2='
        +userForm.value.password2).subscribe(data => {
          this.showNotification('top','center','Modification effectué',1);
          localStorage.removeItem('login');
          localStorage.removeItem('pass');
          localStorage.removeItem('nom');
          localStorage.removeItem('token');
            localStorage.removeItem('isconn');
          this.router.navigate(['login']);
             });

      }else{
        this.showNotification('top','center','Mot de passe incorrect',4);
          }



  
    }

    showNotification(from, align, messagej,t){
      const type = ['','info','success','warning','danger'];
  
      const color = Math.floor((Math.random() * 4) + 1);
  
      $.notify({
          icon: "notifications",
          message: messagej
  
      },{
          type: type[t],
          timer: 4000,
          placement: {
              from: from,
              align: align
          },
          template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
            '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
            '<i class="material-icons" data-notify="icon">notifications</i> ' +
            '<span data-notify="title">{1}</span> ' +
            '<span data-notify="message">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            '</div>' +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
          '</div>'
      });
  }
  
  
  
  
  }
  