
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {FormGroup} from '@angular/forms'
import { NgForm } from '@angular/forms';
//import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { Http, Response } from '@angular/http';
//import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ServiceService } from '../service/service.service';
declare var $: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {


  
    public viewall:boolean=false;
    public showaddform:boolean=false;
    public completform: boolean = false;
    public btncompletform = 'COMPLET FORM';
    public btnaddform = 'CREATE A NEW USER PROFILE';
    public formGroup: FormGroup;
  
    public erreur =false;
    public message='';
   
    public nbuseremailed = 0;
    settings = {
     noDataMessage: "Aucun Utilisateur n'est enrégistré pour le moment!",
     add: {
      addButtonContent: `<img src="../assets/img/plus.png">`,
      createButtonContent: `<img src="../assets/img/checkmark.png">`,
      cancelButtonContent: `<img src="../assets/img/close.png">`,
      confirmCreate: true,
    },
    edit: {
      editButtonContent: `<img src="../assets/img/pencil.png">`,
      saveButtonContent: `<img src="../assets/img/checkmark.png">`,
      cancelButtonContent: `<img src="../assets/img/close.png">`,
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: `<img src="../assets/img/trash.png">`,
      confirmDelete: true,
    },
     columns: {
      username: {
         title: 'login',
         type: 'string',
         editable: false,
       },
       nomUser: {
         title: 'Nom',
         type: 'string',
       },
       prenomUser: {
        title: 'Prenom',
        type: 'string',
      },
       phoneNumber: {
         title: 'phone number',
         type: 'string',
       },
       typeUser: {
         title: 'type utilisateur',
         type: 'string',
         editable: false,
       },
       sexe:{
         title: 'Sexe',
         type: 'string',
         editable: false,
       },
       date_update: {
         title: 'Date creation',
         type: 'string',
         editable: false,
       },
       activated: {
        title: 'actif',
        type: 'string',
      }
     },
     pager: {
       display: true,
       perPage: 7,
     },
     actions: {
      add: false,
       position: 'left',
     },
     attr: {
       class: 'table table-hover'
     }
   };
  
   nbusers = 0;
   error:string;
  
   public items1: any;
   //items1=[];
   //data2: any[];
   public url1:any;
   tableau= [];
  
  typeuser = '';
  nomuser = '';
  login = '';
  sexe = '';
  phonenumber = '';
  
  pnomuser= '';
  email= '';
  adressUser= '';
  profilpicture='../assets/img/photos_utilisateur/face-3.png';
  
     constructor(private http: Http, private router: Router,public param: ServiceService,private http1: HttpClient) { 
  
  
  
  
      //this.data2=[];
  
  
      this.recupuser().subscribe(
        data => {
          this.items1 = JSON.parse(JSON.stringify(data)); 
          this.nbusers = this.items1.length;
  



          this.typeuser = localStorage.getItem('typeuser');
          this.nomuser = localStorage.getItem('nom');
          this.pnomuser = localStorage.getItem('prenomUser');
          this.login = localStorage.getItem('login');
          this.sexe = localStorage.getItem('sexe');
          this.phonenumber = localStorage.getItem('phoneNumber');
          this.email = localStorage.getItem('email');
          this.adressUser = localStorage.getItem('adressUser');
        },
      error => this.error = error.message
          );
  
  
  
  
  
  
  
     }
     
     ngOnInit() {

  
         }
         recupuser() {
          this.url1=this.param.lien+'recup_user.php'
          return this.http
          .get(this.url1)
          .map((data: Response) => data.json())
        }
        open3($event) {
  
          this.typeuser = $event.data.typeUser;
          this.nomuser = $event.data.nomUser;
          this.login = $event.data.username;
          this.sexe = $event.data.sexe;
          this.phonenumber = $event.data.phoneNumber;
      
        }
        toggle() {
          this.router.navigate(['CreerUser']);
        }
  
        
        onDeleteConfirm(event) {
          if (window.confirm('Voulez-vous vraiment supprimer cet utilisateur?')) {
            event.confirm.resolve();
  
            this.http.get(this.param.lien+'delete_user.php?login='+event.data.username+'&login1='+ localStorage.getItem('login')).subscribe(data => {
              this.showNotification('top','center','Supression effectué',1);
                 }); 
          } else {
            event.confirm.reject();
          }
        }
  
        onSaveConfirm(event) {
          this.http.get(this.param.lien+'Modif_user.php?nomUser='+event.newData.nomUser+'&prenomUser='
          +event.newData.prenomUser+'&phoneNumber='+event.newData.phoneNumber+'&activated='+ event.newData.activated
          +'&username='+ event.newData.username+'&login='+ localStorage.getItem('login')).subscribe(data => {
            this.showNotification('top','center','Modification effectué',1);
               }); 
  
        }
  
        onCreateConfirm(event) {
          this.router.navigate(['CreerUser']);
        }
        showNotification(from, align, messagej,t){
          const type = ['','info','success','warning','danger'];
      
          const color = Math.floor((Math.random() * 4) + 1);
      
          $.notify({
              icon: "notifications",
              message: messagej
      
          },{
              type: type[t],
              timer: 4000,
              placement: {
                  from: from,
                  align: align
              },
              template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
                '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
                '<i class="material-icons" data-notify="icon">notifications</i> ' +
                '<span data-notify="title">{1}</span> ' +
                '<span data-notify="message">{2}</span>' +
                '<div class="progress" data-notify="progressbar">' +
                  '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                '</div>' +
                '<a href="{3}" target="{4}" data-notify="url"></a>' +
              '</div>'
          });
      }
   }
   