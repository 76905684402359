
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy, PopStateEvent } from '@angular/common';
import 'rxjs/add/operator/filter';
import { NavbarComponent } from './components/navbar/navbar.component';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import PerfectScrollbar from 'perfect-scrollbar';
declare var $: any;


import 'rxjs/add/operator/map';

import { Http, Response } from '@angular/http';

import { HttpClient } from '@angular/common/http';


import { ServiceService } from './service/service.service';
import { trigger } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  private _router: Subscription;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  private islogin: boolean;
   passok1: boolean=false;
   categorief: any;
   categorief1: any;
   public url1:any;

  constructor( public location: Location, private http: Http, private router: Router,public param: ServiceService,private http1: HttpClient) {
    
    this.islogin=!localStorage.getItem('token');

  }
  recupinfopharmajj() {
    this.url1=this.param.lien+'fpdf1BIS.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recupinfopharmajjp() {
    this.url1=this.param.lien+'fcreer.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recupinfopharmaDT() {
    this.url1=this.param.lien+'fpdf3.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  showNotification(from, align, messagej,t){
    const type = ['','info','success','warning','danger'];

    const color = Math.floor((Math.random() * 4) + 1);

    $.notify({
        icon: "notifications",
        message: messagej

    },{
        type: type[t],
        timer: 4000,
        placement: {
            from: from,
            align: align
        },
        template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
}

  ngOnInit() {



  
    if(!localStorage.getItem('token')){

        this.router.navigate(['login']);
   }else{



    this.categorief  = [];
    this.recupinfopharmajj().subscribe(
      data => {

        this.categorief = JSON.parse(JSON.stringify(data));
        
    
        let ligne=this.categorief[999].titre;
        let taille=this.categorief[3000].titre;
        let DATEO=this.categorief[3001].titre;
        let taille1=+taille;
        let st="";
        for(var i = 1; i <taille1*2; i=i+2){
          st=st+ligne[i];
        }
        var d1 = new Date(0);
        let Y=+st.substring(0,4);
        let M=+st.substring(5,7);
        let D=+st.substring(8,10);
        d1.setFullYear(Y , M, D);
        var d2 = new Date(0);
        let Y2=+DATEO.substring(0,4);
        let M2=+DATEO.substring(5,7);
        let D2=+DATEO.substring(8,10);
        d2.setFullYear(Y2, M2, D2);

              if(d2 > d1){
                localStorage.removeItem('token');
                this.showNotification('top','center','Contacter l\'administrateur pour renouveler la licence',4);
                this.router.navigate(['login']);
                localStorage.setItem('topo1','false');
              }else{
                    this.categorief  = [];
                    this.recupinfopharmaDT().subscribe(
                    data => {
                      this.categorief = JSON.parse(JSON.stringify(data));
                      let ligne=this.categorief[0].ligne;
                      let st="";
                      for(var i = 0; i <ligne.length; i=i+21){
                        st=st+ligne[i];
                      }
                      localStorage.removeItem("DF");
                      localStorage.removeItem("DD");
                      localStorage.setItem("DF",st);
                      localStorage.setItem("DD",this.categorief[1].ligne);
                    },
                      );



                var d1 = new Date(0);
                let Y=+localStorage.getItem("DF").substring(0,4);
                let M=+localStorage.getItem("DF").substring(4,6);
                let D=+localStorage.getItem("DF").substring(6,8);
                d1.setFullYear(Y , M, D);
                var d2 = new Date(0);
                let Y2=+localStorage.getItem("DD").substring(0,4);
                let M2=+localStorage.getItem("DD").substring(5,7);
                let D2=+localStorage.getItem("DD").substring(8,10);
                d2.setFullYear(Y2, M2, D2);
                
                     
                      if(d1 > d2){
                        this.showNotification('top','center','bien vouloir mettre a jour la date',4);
                      }else{
                   this.recupinfopharmajjp().subscribe(
                    data => {
                    },
                      );
                    }



            }
      },
        );




         }
   this.islogin=!localStorage.getItem('token');



   

      const isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;

      if (isWindows && !document.getElementsByTagName('body')[0].classList.contains('sidebar-mini')) {
          // if we are on windows OS we activate the perfectScrollbar function

          document.getElementsByTagName('body')[0].classList.add('perfect-scrollbar-on');
      } else {
          document.getElementsByTagName('body')[0].classList.remove('perfect-scrollbar-off');
      }
      const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
      const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');

      this.location.subscribe((ev:PopStateEvent) => {
          this.lastPoppedUrl = ev.url;
      });
       this.router.events.subscribe((event:any) => {
          if (event instanceof NavigationStart) {
             if (event.url != this.lastPoppedUrl)
                 this.yScrollStack.push(window.scrollY);
         } else if (event instanceof NavigationEnd) {
             if (event.url == this.lastPoppedUrl) {
                 this.lastPoppedUrl = undefined;
                 window.scrollTo(0, this.yScrollStack.pop());
             } else
                 window.scrollTo(0, 0);
         }
      });
      this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
           elemMainPanel.scrollTop = 0;
           elemSidebar.scrollTop = 0;
      });
      if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
          let ps = new PerfectScrollbar(elemMainPanel);
          ps = new PerfectScrollbar(elemSidebar);
      }

      const window_width = $(window).width();
      let $sidebar = $('.sidebar');
      let $sidebar_responsive = $('body > .navbar-collapse');
      let $sidebar_img_container = $sidebar.find('.sidebar-background');


      if(window_width > 767){
          if($('.fixed-plugin .dropdown').hasClass('show-dropdown')){
              $('.fixed-plugin .dropdown').addClass('open');
          }

      }

      $('.fixed-plugin a').click(function(event){
        // Alex if we click on switch, stop propagation of the event, so the dropdown will not be hide, otherwise we set the  section active
          if($(this).hasClass('switch-trigger')){
              if(event.stopPropagation){
                  event.stopPropagation();
              }
              else if(window.event){
                 window.event.cancelBubble = true;
              }
          }
      });

      $('.fixed-plugin .badge').click(function(){
          let $full_page_background = $('.full-page-background');


          $(this).siblings().removeClass('active');
          $(this).addClass('active');

          var new_color = $(this).data('color');

          if($sidebar.length !== 0){
              $sidebar.attr('data-color', new_color);
          }

          if($sidebar_responsive.length != 0){
              $sidebar_responsive.attr('data-color',new_color);
          }
      });

      $('.fixed-plugin .img-holder').click(function(){
          let $full_page_background = $('.full-page-background');

          $(this).parent('li').siblings().removeClass('active');
          $(this).parent('li').addClass('active');


          var new_image = $(this).find("img").attr('src');

          if($sidebar_img_container.length !=0 ){
              $sidebar_img_container.fadeOut('fast', function(){
                 $sidebar_img_container.css('background-image','url("' + new_image + '")');
                 $sidebar_img_container.fadeIn('fast');
              });
          }

          if($full_page_background.length != 0){

              $full_page_background.fadeOut('fast', function(){
                 $full_page_background.css('background-image','url("' + new_image + '")');
                 $full_page_background.fadeIn('fast');
              });
          }

          if($sidebar_responsive.length != 0){
              $sidebar_responsive.css('background-image','url("' + new_image + '")');
          }
      });
  }
  state(): boolean {
    this.islogin=!localStorage.getItem('token');    
   return !localStorage.getItem('token');
   }
  ngAfterViewInit() {
      this.runOnRouteChange();
  }
  isMaps(path){
      var titlee = this.location.prepareExternalUrl(this.location.path());
      titlee = titlee.slice( 1 );
      if(path == titlee){
          return false;
      }
      else {
          return true;
      }
  }
  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
      const ps = new PerfectScrollbar(elemMainPanel);
      ps.update();
    }
  }
  isMac(): boolean {
      let bool = false;
      if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
          bool = true;
      }
      return bool;
  }

}
